/**
 * Created by Administrator on 2017/10/08 0008.
 */
import * as THREE from '../three.js/build/three.js';
import  '../libs/dat.gui.js';
import * as DDSLoader from './DDSLoader.js';
import * as MTLLoader from './MTLLoader.js';
import * as OBJLoader from './OBJLoader.js';
import * as Orbit_1 from './Orbit_1.js';
import * as OrbitControls from './OrbitControls.js';
var tdmodel = function test() {
    var model_inturl = 'https://chenyifaster-3dmodelfiles.oss-cn-hangzhou-internal.aliyuncs.com/';
    // var model_inturl = 'static/3d/';
    //定义环境及插件相关
    var scene, //场景
            camera, //相机
            temp_camera,
            renderer, //渲染器
            ambient, //环境光
            dirlight1, //点光源
            dirlight2, //点光源
            group, //对象组
            orbitControls, //轨道控制器
            guicontrols, //gui控制器
            clock;  //计时器（轨道控制器的）
//            tween;

    //新设计OR二次设计
    var again_design_type = 0; //0 新设计  1 二次设计
    var again_design_data = {

    }; //二次设计时的要展示的模型数据
    var all_data = {

    };

    //过程处理
    var cur_obj, //当前对象
            cur_selected, //当前选中对象
            status_rotation, //旋转状态
            status_load, //载入状态
            status_scale = 0, //缩放状态
            status_enabled_emb_pri = 0, //刺绣印花启用状态  0都没使用 1刺绣  2印花  3都启用 4绣花2  5绣花1和绣花2 6绣花1和印花 7 绣花2和印花
            cur_shirt_long = 1, //1 长袖 2 短袖
            cur_show_pocket = 1; //1 无 2 有
    // var     cur_scene_load_st = 1; //验证场景所有模型是否加载结束

    //绣花(刺绣)
    var emb_content = '', //绣花文字内容
            emb_font = 'FZCuQian-M175', //绣花字体（风格）
            emb_color = '#1239a2', //绣花颜色
            emb_part_number = 1, //绣花部位 1 2 3 4 5 6
            emb_type = 2, //绣花类型 1文字  2生肖 3星座
            emb_style_type = '', //非文字 绣花图片款式类型
            emb_style_number = 1, //非文字 绣花图片款式编号
            emb_color_number = 1;    //非文字 绣花图片颜色编号

    var     emb_content1 = '', //绣花文字内容
            emb_font1 = 'FZCuQian-M175', //绣花字体（风格）
            emb_color1 = '#1239a2', //绣花颜色
            emb_part_number1 = 1, //绣花部位 1 2 3 4 5 6
            emb_type1 = 2, //绣花类型 1文字  2生肖 3星座
            emb_style_type1 = '', //非文字 绣花图片款式类型
            emb_style_number1 = 1, //非文字 绣花图片款式编号
            emb_color_number1 = 1;    //非文字 绣花图片颜色编号

    //印花
    var print_type = 1, //绣花类型 1山水  2花鸟 3其它
            print_style_number = 1;   //印花款式编号;

    //模型目录结构
    //当前 OBJ 与 MTL 路径  example: 1号面料的1号部位的1号款式模型及对应贴图为：“model/1/1.obj model/1/1.mtl && map/1.png"
    var Part_map = 'Part_map', //部位款式模型贴图（面料）
            Emb_map = 'Emb_map', //刺绣贴图
            Printing_map = 'Printing_map', //印花贴图
            Button_map = 'Button_map', //钮扣模型贴图
            Button_model = 'Button_model', //钮扣模型
            Human_body = 'Human_body', //人体模型
            Model_hangers = 'Model_hangers', //模型衣架
            Part_model = 'Part_model', //部位模型

            category = 1, //品类 1 Shirt  2 Suit
            model_sex = 1, //模型性别 1 Man  2 Woman
            goods_model_number = 1, //商品模型编号 1 第一套模型
            goods_map_number = {1: 1, 2: 1, 3: 1, '4-1': 1, '4-2': 1, '5-1': 1, '5-2': 1, '6-1': 1, '6-2': 1, 7: 1, '8-1': 1, '8-2': 1, '9-1': 1, '9-2': 1, 10: 1, 11: 1, 12: 1, 13: 1}, //商品各部位对应编号下的贴图编号(面料号) X-1/2 为多材质正反面 （4的款式1只有正面）
    part_style_record = {}, //商品当前所有部位款式记录
            part_number = 1, //部位编号
            style_number = 1, //款式编号
            human_body_model_number = 1, //人体模型编号 1 第一套模型
            model_hangers_model_number = 1;  //模型衣架的模型编号 1 第一套模型

    //钮扣(随着钮扣模型切换扣线模型也对应切换，扣眼模型不变)(扣线和扣子是对应的就直接使用扣子的数据)
    var button_part_style_record = {1: 1, 2: 0, 3: 1, 4: 1, 5: 1}, //商品扣子当前所有部位款式记录
    //ps: 主要用于切换扣子
    button_model_number = 1, //钮扣模型编号 1 第一套模型
            button_material_number = 1, //钮扣材质编号 1 第一种材质
            button_map_number = 1, //钮扣贴图编号(钮扣号) 1 第一种贴图

            //ps：主要用于切换款式
            button_part_number = 1, //钮扣模型 部位编号
            button_style_number = 1, //钮扣模型  款式编号
            button_hole_model_number = 1; //钮眼模型编号 1 第一套模型 （只要扣眼模型不可能不对应所以单独出来）

    //法式扣（没有扣线）（扣眼，扣子模型等数据直接也用钮扣变量表示）
    var fa_button_part_style_record = {4: 7, 5: 1}; //商品扣子当前所有部位款式记录

    var cur_model_max_count = 13, //完整一套模型MAX个数
            cur_model_style_max_count = 10, //MAX款式数
            cur_assorted_part_number = 1, //当前拼色部位号
            cur_assorted_style_number = 1, //当前拼色款式号
            cur_assorted_part_face = 1;  //当前拼色部位面 1 材质1(mianliao1)  2材质 3(mianliao2)
    var cur_model_load_st = 1;  //当前模型加载状态  1 进行中 2 完成

    var canvas = document.getElementById("mainCanvas");
    if (category == 1) {
        category = 'Shirt';
    }
    if (model_sex == 1) {
        model_sex = 'Man';
    }
    if(model_sex == 2){
        model_sex = 'Woman';
    }
    var TweenMax = new TimelineMax();
    init_scene();
    //加载OBJ 与 MTL (模型和皮肤)  模型名称、模型类别、重载状态(加载的实际对象用全局变量来标识，并不是此处的模型名称，导致了一些问题，后期处理？)
    function load_obj_mtl(load_model_name, load_model_type, reload_st) {  //设置对象名称   (load_model_type 1商品  2衣架 3人体 4扣子)
        // getOsssign();
        cur_model_load_st = 1;
        var model_url = '';  //模型目录
        var model_files = ''; //模型文件
        var map_url = '';  //贴图目录
        var map_files = ''; //贴图文件
        var map_files1 = ''; //贴图文件（双面情况）
        var button_type = 1;  //1普通扣 2法式
        switch (load_model_type) {
            case 1:     //商品模型
                model_url = model_inturl+'model/formal/' + category + '/' + model_sex + '/' + goods_model_number + '/' + Part_model + '/' + part_number + '/';

                model_files = style_number;
                map_url = model_inturl+'model/formal/' + category + '/' + Part_map + '/';
                if ((part_number == 4 && style_number != 1) || part_number == 5 || part_number == 6 || part_number == 8 || part_number == 9) {
                    map_files = goods_map_number[part_number + '-' + 1];
                    map_files1 = goods_map_number[part_number + '-' + 2];
                } else if (part_number == 4 && style_number == 1) {
                    map_files = goods_map_number[part_number + '-' + 1];
                } else {
                    map_files = goods_map_number[part_number];
                }
                part_style_record[part_number] = style_number;  //记录所有当前显示部位及对应款式
//                    console.log('part_style_record:');
//                    console.log(part_style_record);
                if (part_number == 7 && style_number == 7) {//当口袋为无得时候，因为没有模型，所以这里直接将模型是否加载完毕判断为加载完，防止错误弹层
                    cur_model_load_st = 2;
                }
                break;
            case 2:     //模型衣架
                model_url =model_inturl+ 'model/formal/' + category + '/' + model_sex + '/' + goods_model_number + '/' + Model_hangers + '/' + 'model/';
                model_files = model_hangers_model_number;
                break;
            case 3:     //人体模型
                model_url = model_inturl+'model/formal/' + category + '/' + model_sex + '/' + goods_model_number + '/' + Human_body + '/' + 'model/';
                model_files = human_body_model_number;
                break;
            case 4:     //钮扣模型
                if (button_model_number > 100) {
                    model_url = model_inturl+'model/formal/' + category + '/' + model_sex + '/' + goods_model_number + '/' + Button_model + '/fa_button/' + button_model_number + '/' + button_material_number + '/' + button_part_number + '/';
                    model_files = button_style_number;
                    fa_button_part_style_record[button_part_number] = button_style_number;  //记录所有当前显示部位及对应款式
                    map_url = model_inturl+'model/formal/' + category + '/' + Button_map + '/fa_button_map' + '/';
                    map_files = button_map_number;
                    button_type = 2;
                } else {
                    model_url = model_inturl+'model/formal/' + category + '/' + model_sex + '/' + goods_model_number + '/' + Button_model + '/button/' + button_model_number + '/' + button_material_number + '/' + button_part_number + '/';
                    model_files = button_style_number;
                    button_part_style_record[button_part_number] = button_style_number;  //记录所有当前显示部位及对应款式
                    map_url =model_inturl+'model/formal/' + category + '/' + Button_map + '/button_map' + '/';
                    map_files = button_map_number;
                    button_type = 1;
                }
                break;
            case 5:     //扣眼模型
                if (button_model_number > 100) {  //法式
                    model_url =model_inturl+ 'model/formal/' + category + '/' + model_sex + '/' + goods_model_number + '/' + Button_model + '/fa_button_hole/' + button_hole_model_number + '/' + button_material_number + '/' + button_part_number + '/';
                    model_files = button_style_number;
                } else {
                    model_url =model_inturl+ 'model/formal/' + category + '/' + model_sex + '/' + goods_model_number + '/' + Button_model + '/button_hole/' + button_hole_model_number + '/' + button_material_number + '/' + button_part_number + '/';
                    model_files = button_style_number;
                }
                break;
            case 6:     //扣线模型
                model_url = model_inturl+'model/formal/' + category + '/' + model_sex + '/' + goods_model_number + '/' + Button_model + '/button_line/' + button_model_number + '/' + button_material_number + '/' + button_part_number + '/';
                model_files = button_style_number;
                break;
            case 7:   //刺绣层
                model_url = model_inturl+'model/formal/' + category + '/' + model_sex + '/' + goods_model_number + '/' + Part_model + '/' + part_number + '/';
                model_files = style_number;
                if (emb_type > 1) {    //生肖 星座 其它
                    map_url = model_inturl+'model/formal/' + category + '/' + Emb_map + '/' + emb_type + '/' + emb_style_number + '/';
                    map_files = emb_color_number;
                } else {
                    map_files = create_canvas_map(emb_content, emb_font, emb_color, part_number);
                }

                break;
            case 8:   //印花层
                model_url = model_inturl+'model/formal/' + category + '/' + model_sex + '/' + goods_model_number + '/' + Part_model + '/' + 201 + '/';
                model_files = 1;
                map_url = model_inturl+'model/formal/' + category + '/' + Printing_map + '/' + print_type + '/';
                map_files = print_style_number;
                break;
            case 9:   //刺绣1
                model_url = model_inturl+'model/formal/' + category + '/' + model_sex + '/' + goods_model_number + '/' + Part_model + '/' + parseInt(part_number-50) + '/';
                model_files = style_number;
                if (emb_type1 > 1) {    //生肖 星座 其它
                    map_url = model_inturl+'model/formal/' + category + '/' + Emb_map + '/' + emb_type1 + '/' + emb_style_number1 + '/';
                    map_files = emb_color_number1;
                } else {
                    map_files = create_canvas_map1(emb_content1, emb_font1, emb_color1, parseInt(part_number-50));
                }
                break;
        }

           // console.log(map_files);
           // console.log(map_url + map_files);
//        cur_model_load_st=1;
        var onProgress = function (xhr) {
            if (xhr.lengthComputable) {
                var percentComplete = xhr.loaded / xhr.total * 100;
                // console.log( Math.round(percentComplete, 2) + '% downloaded' );  //载入进度百分比
            }
        };
        var onError = function (xhr) {
        };
        THREE.Loader.Handlers.add(/\.dds$/i, new THREE.DDSLoader());
        var mtlLoader = new THREE.MTLLoader();
        mtlLoader.setPath(model_url);
        mtlLoader.load(model_files + '.mtl', function (materials) {
            //因为多材质加载到模型后的材质数组可以是重复平铺过的，就是有多个相同贴图交接的数据。
            //所以需要在加载前对原始的材质数据进行处理。
            materials.preload();  //预加载
            var objLoader = new THREE.OBJLoader();
            objLoader.setMaterials(materials);
            objLoader.setPath(model_url);
            objLoader.load(model_files + '.obj', function (object) {
//                    console.log(object.children[0].material);
                if (load_model_type == 1 && reload_st == 1) {  //重载商品模型加贴图  首次加载模型已经有贴图(换款式当前面料不变)
//                        object.children[0].material.map = THREE.ImageUtils.loadTexture(map_url + map_files + '.jpg');
                    //注意重载贴图后整个纹理配置都会重新设置，为了保留原3D中纹理配置，只取重载的贴图的image数据
                    var temp_map = THREE.ImageUtils.loadTexture(map_url + map_files + '.jpg');
                    // console.log('output '+map_url+map_files);
                    if (map_files1 != null || map_files1 != '') {
                        var temp_map1 = THREE.ImageUtils.loadTexture(map_url + map_files1 + '.jpg');
                    }
                    var temp_material = object.children[0].material;
                    if(model_sex == 'Woman'){
                        if(temp_material instanceof Array){  //女装个别部位没有缝线 所以下面不能遍历数组
                            for(var temp_k in temp_material){
//                            console.log(temp_material[temp_k].map);
                                if(temp_material[temp_k].map != null && temp_material[temp_k]['name'] == 'mianliao1'){   //贴到对象后的材质数据跟原始材质不同，且name为fengxian的是缝线 暂时不变化
                                    temp_material[temp_k].map.image = temp_map.image;
                                }
                                if(temp_material[temp_k].map != null && temp_material[temp_k]['name'] == 'mianliao2'){   //贴到对象后的材质数据跟原始材质不同，且name为fengxian的是缝线 暂时不变化
                                    temp_material[temp_k].map.image = temp_map1.image;
                                }
                            }
                        } else {
                            if(temp_material.map != null && temp_material['name'] == 'mianliao1'){   //贴到对象后的材质数据跟原始材质不同，且name为fengxian的是缝线 暂时不变化
                                temp_material.map.image = temp_map.image;
                            }
                        }
                    }else{
                        for (var temp_k in temp_material) {
//                            console.log(temp_material[temp_k].map);
                            if (temp_material[temp_k].map != null && temp_material[temp_k]['name'] == 'mianliao1') {   //贴到对象后的材质数据跟原始材质不同，且name为fengxian的是缝线 暂时不变化
                                temp_material[temp_k].map.image = temp_map.image;
                            }
                            if (temp_material[temp_k].map != null && temp_material[temp_k]['name'] == 'mianliao2') {   //贴到对象后的材质数据跟原始材质不同，且name为fengxian的是缝线 暂时不变化
                                temp_material[temp_k].map.image = temp_map1.image;
                            }
                        }
                    }
                }
                if (load_model_type == 1 && reload_st == 0){
                    map_files =1;
                }

                if (load_model_type == 4 && reload_st == 1) {  //重载扣子模型加贴图  首次加载模型已经有贴图(换款式当前扣子贴图不变)
                    //注意重载贴图后整个纹理配置都会重新设置，为了保留原3D中纹理配置，只取重载的贴图的image数据
                    if (button_type == 2) { //法式扣目前不变贴图
//                            var temp_map = THREE.ImageUtils.loadTexture(map_url + map_files + '.png'); //法式
                    } else {
                        var temp_map = THREE.ImageUtils.loadTexture(map_url + map_files + '.jpg');
                        var temp_material = object.children[0].material;
                        if (temp_material.map != null) {
                            temp_material.map.image = temp_map.image;
                        }
                    }
//                        console.log(temp_material.map.image);
                }
                if (load_model_type == 7) {  //刺绣层
                    if (emb_type == 1) {    //输入文字
//                            object.children[0].material.map.image = temp_map.image;
//                            temp_map.wrapS = THREE.RepeatWrapping;
//                            temp_map.wrapT = THREE.RepeatWrapping;
//                            temp_map.repeat.set(2,2);  //但不同部位需要不同平铺大小
                        object.children[0].material.map = map_files;
                    } else {   //生肖 星座 其它
                        var temp_map = THREE.ImageUtils.loadTexture(map_url + map_files + '.png');
                        object.children[0].material.map.image = temp_map.image;
                    }
                    object.children[0].material.transparent = true;
                    object.children[0].material.opacity = 1;
                    object.children[0].material.needsUpdate = true;
                    object.children[0].material.side = THREE.DoubleSide;
                }else if (load_model_type == 9) {  //刺绣1
                    if (emb_type1 == 1) {    //输入文字
//                            object.children[0].material.map.image = temp_map.image;
//                            temp_map.wrapS = THREE.RepeatWrapping;
//                            temp_map.wrapT = THREE.RepeatWrapping;
//                            temp_map.repeat.set(2,2);  //但不同部位需要不同平铺大小
                        object.children[0].material.map = map_files;
                    } else {   //生肖 星座 其它
                        var temp_map = THREE.ImageUtils.loadTexture(map_url + map_files + '.png');
                        object.children[0].material.map.image = temp_map.image;
                    }
                    object.children[0].material.transparent = true;
                    object.children[0].material.opacity = 1;
                    object.children[0].material.needsUpdate = true;
                    object.children[0].material.side = THREE.DoubleSide;
                } else if (load_model_type == 8) {   //印花层
                    var temp_map = THREE.ImageUtils.loadTexture(map_url + map_files + '.png');
                    object.children[0].material.map.image = temp_map.image;
                    object.children[0].material.transparent = true;
                    object.children[0].material.opacity = 1;
                    object.children[0].material.needsUpdate = true;
                    object.children[0].material.side = THREE.DoubleSide;
                    //            temp_material[temp_k].wrapS = THREE.RepeatWrapping;
                    //            temp_material[temp_k].wrapT = THREE.RepeatWrapping;
                    //            temp_material[temp_k].repeat.set(28,30);  //但不同部位需要不同平铺大小
                } else if (load_model_type == 4 || load_model_type == 5 || load_model_type == 6) {  //扣子及相关
                    object.children[0].material.needsUpdate = true;
                    object.children[0].material.side = THREE.DoubleSide;
                } else {
                    if(object.children[0].material instanceof Array){
                        try{
                            for(var temp_m in object.children[0].material){
                                object.children[0].material[temp_m].needsUpdate = true;
                                object.children[0].material[temp_m].side = THREE.DoubleSide;
                            }
                        } catch(error) {
                            throw ('材质数据不是对象,可能为缺少材质数据！');
                        }
                    } else {
                        object.children[0].material.needsUpdate = true;
                        object.children[0].material.side = THREE.DoubleSide;
                    }

                    // try {
                    //     for (var temp_m in object.children[0].material) {
                    //         object.children[0].material[temp_m].needsUpdate = true;
                    //         object.children[0].material[temp_m].side = THREE.DoubleSide;
                    //     }
                    // } catch (error) {
                    //     throw ('材质数据不是对象,可能为缺少材质数据！');
                    // }
                }

                object.scale.set(1.0, 1.0, 1.0);
                object.name = load_model_name; //当前对象编号
                cur_obj = object;//储存到全局变量中
                //            console.log(object);
                status_load = 1;
                group.add(object);
                scene.add(group);

                cur_model_load_st = 2;

            }, onProgress, onError);
        });
    }

    //切换模型(obj 与 mtl)(款式）
     window.changeStyle = function changeStyle( part_number1,style_number1){
        if (cur_model_load_st == 1) {
            alert('请等待模型加载完毕！');
            return false;
        }
        part_number = part_number1;  //部位
        style_number = style_number1; //款式
        // $('.switch_assorted_part').each(function () {
        //     if ($(this).data('assorted-part-number') == part_number) {
        //         $(this).data('assorted-style-number', style_number);  //PS：坑，data设置的属性值，页面上看着是没有变化的 所以为什么不用attr反而推荐data暂时不清楚
        //     }
        //     if (part_number == 5) { //6切回5时候把6的也需要变动
        //         if ($(this).data('assorted-part-number') == 6) {
        //             $(this).data('assorted-style-number',1);
        //         }
        //     }
        // });
         if (part_number == 2){ //选择后背-女装
             if(scene.getObjectByName(2+'-'+part_style_record[2]) != undefined){
                 scene.children[4].remove(scene.getObjectByName(2+'-'+part_style_record[2]));  //ps: remove只能删除对象的直接子级对象
             }
             if(scene.getObjectByName(12+'-'+part_style_record[12]) != undefined){
                 scene.children[4].remove(scene.getObjectByName(12+'-'+part_style_record[12]));  //ps: remove只能删除对象的直接子级对象
             }
             if(scene.getObjectByName(13+'-'+part_style_record[13]) != undefined){
                 scene.children[4].remove(scene.getObjectByName(13+'-'+part_style_record[13]));  //ps: remove只能删除对象的直接子级对象
             }
             THREE.Cache.clear();  //清除缓存
             //先清除场景里对应部位下所有存在的款式模型
             load_obj_mtl(part_number + '-' + style_number, 1, 1); //后背
             load_button_all(1);
             part_number = 12;
             if(part_style_record[3] == 2){   //本来是V款时
                 style_number = style_number + 3;
             }
             load_obj_mtl(part_number + '-' + style_number, 1, 1); //左前片
             load_button_all(1);
             part_number = 13;
             load_obj_mtl(part_number + '-' + style_number, 1, 1); //右前片
             load_button_all(1);
             show_emb();
         }else if (part_number == 5 || part_number == 6) {  //选择上下领
            //先清除场景里对应部位下所有存在的款式模型
            for (var i = 5; i <= 6; i++) {
                if (scene.getObjectByName(i + '-' + part_style_record[i]) != undefined) {
                    scene.children[4].remove(scene.getObjectByName(i + '-' + part_style_record[i]));  //ps: remove只能删除对象的直接子级对象
                }
            }
            THREE.Cache.clear();  //清除缓存
            if (part_number == 5) { //选择上领款式时 下领统一为款式1
                load_obj_mtl(part_number + '-' + style_number, 1, 1);
                load_button_all(1);
                part_number = 6;
                style_number = 1;
                load_obj_mtl(part_number + '-' + style_number, 1, 1);
                load_button_all(1);
                show_emb();
            } else if (part_number == 6 && (style_number == 2 || style_number == 3)) {  //选择下领时上领为空且款式只能为2、3
                load_obj_mtl(6 + '-' + style_number, 1, 1);
                load_button_all(1);
                show_emb();
            }
        } else if (part_number == 8 || part_number == 9) { //选择长袖 短袖时
            //先清除场景里对应部位下所有存在的款式模型
            for (var i = 8; i <= 11; i++) {
                if (scene.getObjectByName(i + '-' + part_style_record[i]) != undefined) {
                    scene.children[4].remove(scene.getObjectByName(i + '-' + part_style_record[i]));  //ps: remove只能删除对象的直接子级对象
                }
            }
            THREE.Cache.clear();  //清除缓存
            if (part_number == 8) { //选长袖时 短袖隐藏 显示长袖、袖褶、袖叉
                load_obj_mtl(8 + '-' + style_number, 1, 1);
                load_button_all(1);
                cur_shirt_long = 1;  //长袖标识 先标识后印花
                show_emb();
                if (style_number > 6) { //进一步判断是否为法式
                    part_number = 10;
                    if (part_style_record[10] < 3) { //如果袖褶不是法式的就切成默认法式袖褶1，如果本来已经是就不变
                        style_number = 3;
                    } else {
                        style_number = part_style_record[10];
                    }
                    load_obj_mtl(10 + '-' + style_number, 1, 1); //袖褶
                    part_number = 11;
                    if (part_style_record[10] < 3) { //袖叉同袖褶
                        style_number = 3;
                    } else {
                        style_number = part_style_record[10];
                    }
                    load_obj_mtl(11 + '-' + style_number, 1, 1); //袖叉
                    load_button_all(1);
                } else {
                    part_number = 10;
                    if (part_style_record[10] > 2) { //如果袖褶不是普通的就切成默认普通袖褶2，如果本来已经是就不变
                        style_number = 2;
                    } else {
                        style_number = part_style_record[10];
                    }
                    load_obj_mtl(10 + '-' + style_number, 1, 1); //袖褶
                    part_number = 11;
                    if (part_style_record[10] > 2) { //袖叉同袖褶
                        style_number = 1;
                    } else {
                        style_number = part_style_record[10];
                    }
                    load_obj_mtl(11 + '-' + style_number, 1, 1); //袖叉
                    load_button_all(1);
                }
            } else if (part_number == 9) {  //选短袖时 显示短袖  长袖、袖褶、袖叉隐藏
                load_obj_mtl(9 + '-' + style_number, 1, 1);
                load_button_all(1);
                cur_shirt_long = 2;  //短袖标识 先标识后印花
                show_emb();
            }
        } else {
            //先清除场景里对应部位下所有存在的款式模型
            if (scene.getObjectByName(part_number + '-' + part_style_record[part_number]) != undefined) {
                scene.children[4].remove(scene.getObjectByName(part_number + '-' + part_style_record[part_number]));  //ps: remove只能删除对象的直接子级对象
            }
            THREE.Cache.clear();  //清除缓存
            if (part_number != 7) {  //非口袋的情况
                load_obj_mtl(part_number + '-' + style_number, 1, 1);
                load_button_all(1);
            } else {
                if (style_number != 999) {
                    load_obj_mtl(part_number + '-' + style_number, 1, 1);
                    cur_show_pocket = 2;  //有口袋标记
                } else {
                    cur_show_pocket = 1;   //无口袋标记
                }
            }
            show_emb();
        }

        $("#switch_word").text($(this).text());
//            document.getElementById('word').innerHTML = e.innerHTML;
    };

    //更改整体模型
    function changeallmodel(fabric_id, is_salevoer, is_self_fabric) {
        if (is_salevoer == 1) {
            //如果为售罄面料就不执行操作
            return false;
        }
        if (is_self_fabric == 1) {
            //为1时此处不操作自备面料
            return false;
        }
        var map_number = fabric_id; //贴图号（面料号）
        var map_url = model_inturl+'model/formal/' + category + '/' + Part_map + '/';
//            scene.getObjectByName(part_number+'-'+style_number).children[0].material.map = THREE.ImageUtils.loadTexture(map_url + map_number + '.jpg');
        var temp_map = THREE.ImageUtils.loadTexture(map_url + map_number + '.jpg');
        // console.log(fabric_id);return ;
        //当前所有模型贴图切换为当前选择面料
        if(model_sex == 'Woman'){
            cur_model_style_max_count = 9;
        }else{
            cur_model_style_max_count = 10;
        }
        for (var i = 1; i <= cur_model_max_count; i++) {
            for (var j = 1; j <= cur_model_style_max_count; j++) {
                if (scene.getObjectByName(i + '-' + j) != undefined) {
                    var temp_obj = scene.getObjectByName(i + '-' + j).children[0].material;
                    // console.log(temp_obj);
                    //（1）注意重载贴图后整个纹理配置都会重新设置，为了保留原3D中纹理配置，只取重载的贴图的image数据

                    if(model_sex == 'Woman'){
                        //（1）注意重载贴图后整个纹理配置都会重新设置，为了保留原3D中纹理配置，只取重载的贴图的image数据
                        if(temp_obj instanceof Array){ //女装个别部位没有缝线 所以下面不能遍历
                            for(var temp_k in temp_obj){
//                            console.log(temp_obj[temp_k]['name']);
                                if(temp_obj[temp_k].map != null && temp_obj[temp_k]['name'] != 'fengxian'){   //贴到对象后的材质数据跟原始材质不同，且name为fengxian的是缝线 暂时不变化
                                    temp_obj[temp_k].map.image = temp_map.image;
                                    //（2）因为重新设置整个map时，纹理会自动更新，但因为操作（1）只取了image数据，所以只纹理不会自动更新，需要手动更新（PS:初始时设置新image不需要更新，是因为对象还没添加到场景里，就是说还没真正创建出来）
                                    temp_obj[temp_k].map.needsUpdate = true;
//                                console.log(i+'-'+j);
                                }
                            }
                        } else {
                            if(temp_obj.map != null && temp_obj['name'] != 'fengxian'){   //贴到对象后的材质数据跟原始材质不同，且name为fengxian的是缝线 暂时不变化
                                temp_obj.map.image = temp_map.image;
                                //（2）因为重新设置整个map时，纹理会自动更新，但因为操作（1）只取了image数据，所以只纹理不会自动更新，需要手动更新（PS:初始时设置新image不需要更新，是因为对象还没添加到场景里，就是说还没真正创建出来）
                                temp_obj.map.needsUpdate = true;
//                                console.log(i+'-'+j);
                            }
                        }
                    }else{
                        for (var temp_k in temp_obj) {
                            if (temp_obj[temp_k].map != null && temp_obj[temp_k]['name'] != 'fengxian') {   //贴到对象后的材质数据跟原始材质不同，且name为fengxian的是缝线 暂时不变化
                                temp_obj[temp_k].map.image = temp_map.image;
                                //（2）因为重新设置整个map时，纹理会自动更新，但因为操作（1）只取了image数据，所以只纹理不会自动更新，需要手动更新（PS:初始时设置新image不需要更新，是因为对象还没添加到场景里，就是说还没真正创建出来）
                                temp_obj[temp_k].map.needsUpdate = true;
                            }
                        }
                    }
                }
            }
        }
        for (var temp_number in goods_map_number) {  //更新当前贴图号（面料号）
            goods_map_number[temp_number] = map_number
        }

//            var Img = new Image();
//            Img.src = map_url + map_number + '.jpg';
//            Img.onload = function (){
//                temp_map.needsUpdate = true;
//                scene.getObjectByName(part_number+'-'+style_number).children[0].material = true;
//            };
    }


    //切换贴图(map)(面料)(整体)
    window.changeMapAll = function(point_mmap){
            var fabric_id = point_mmap; //贴图号（面料号）
            window.point_mmap = fabric_id;
            // console.log(fabric_id);
            if ($(this).find('i').hasClass('icon-shouqing1')) {
                //如果为售罄面料
                var is_salevoer = 1
            } else {
                var is_salevoer = 2;
            }
            if (fabric_id == 145) {
                //自备面料标识
                var is_self_fabric = 1;
                $("#partnum").attr('data-num', fabric_id);
            } else {
                var is_self_fabric = 2;
            }
            changeallmodel(fabric_id, is_salevoer, is_self_fabric);
    };

    //自备面料切换模型
    $(".self_sure").on('click', function () {
        var fabric_id = $("#partnum").data('num');
        var is_salevoer = 2;//面料未售罄
        var is_self_fabric = 2;//自备面料标识
        //取当前选择拼色信息
        var is_part_number = $('.cur_choice').data('assorted-part-number'); //拼色部位号
        var is__style_number = $('.cur_choice').data('assorted-style-number'); //拼色款式号
        var is__part_face = $('.cur_choice').data('assorted-part-face'); //拼色部位面
        if (is_part_number && is__style_number && is__part_face) {
            changesubmodel(fabric_id, is_self_fabric);
        } else {
            changeallmodel(fabric_id, is_salevoer, is_self_fabric);
        }

    });

    //1切换当前拼色部位及部位的面（面：PS 适用于多面部位情况 1表(材质1-mianliao1) 2里(材质2-mianliao2)）
    // $('.switch_assorted_part').click(function () {
    //     $(this).addClass('cur_choice').siblings('.switch_assorted_part').removeClass('cur_choice');
//            cur_assorted_part_number = $(this).data('assorted-part-number'); //拼色部位号
//            cur_assorted_style_number = $(this).data('assorted-style-number'); //拼色部位号
//            cur_assorted_part_face = $(this).data('assorted-part-face'); //拼色部位面
    // });
	
	/**
	 * @param {Object} cur_assorted_part_number
	 * @param {Object} cur_assorted_style_number
	 * @param {Object} assorted_map_number
	 * 处理拼色贴图
	 */
	function doTempMap(cur_assorted_part_number,cur_assorted_style_number,assorted_map_number){
		var assorted_map_url =model_inturl+ 'model/formal/' + category + '/' + Part_map + '/';
		// console.log(assorted_map_url+assorted_map_number);
		var temp_map = THREE.ImageUtils  .loadTexture(assorted_map_url + assorted_map_number + '.jpg');
		var temp_material = scene.getObjectByName(cur_assorted_part_number + '-' + cur_assorted_style_number).children[0].material;
		//多材质部位  1表(材质1-mianliao1) 2里(材质2-mianliao2)
		    for (var temp_k in temp_material) {
		        if (temp_material[temp_k].map != null && temp_material[temp_k]['name'] == 'mianliao2') {   //贴到对象后的材质数据跟原始材质不同，且name为fengxian的是缝线 暂时不变化
		            temp_material[temp_k].map.image = temp_map.image;
		            temp_material[temp_k].map.needsUpdate = true;
		        }
				// temp_material[temp_k].map.image = temp_map.image;
				// temp_material[temp_k].map.needsUpdate = true;
		    }
		    for (var temp_k in temp_material) {
		        if (temp_material[temp_k].map != null && temp_material[temp_k]['name'] == 'mianliao1') {   //贴到对象后的材质数据跟原始材质不同，且name为fengxian的是缝线 暂时不变化
		            temp_material[temp_k].map.image = temp_map.image;
		            temp_material[temp_k].map.needsUpdate = true;
		        }
		    }
		
		//更新当前贴图号（面料号）
		    goods_map_number[cur_assorted_part_number + '-' + cur_assorted_part_face] = assorted_map_number;
		    goods_map_number[cur_assorted_part_number + '-' + 1] = assorted_map_number;
		    goods_map_number[cur_assorted_part_number] = assorted_map_number;
	}
	
    //2切换贴图(map)(面料)(拼色)
    function changesubmodel(map_sub_type,assorted_map_number, is_self_fabric) {
        if (is_self_fabric == 1) {
            //为1时此处不操作自备面料
            return false;
        }
		// if(assorted_map_number == window.point_mmap){
		// 	return false;
		// }
		var map_sub_type = map_sub_type;//拼色类型
		if(map_sub_type == 1){//无拼色
			 return false;
		}
        // console.log(part_style_record[5]);
        // console.log(cur_assorted_part_number);
        // console.log(cur_assorted_style_number);
		if(map_sub_type == 2){//领子拼色
			doTempMap(5,part_style_record[5],assorted_map_number);//上领
			doTempMap(6,part_style_record[6],assorted_map_number);//下领
		}
		if(map_sub_type == 3){//袖口拼色
		    // console.log('long'+cur_shirt_long);
		    if(cur_shirt_long == 2){//短袖
                alert('对不起，短袖口暂时不支持拼色');
		        return ;
                // doTempMap(9,part_style_record[9],assorted_map_number);//
            }else{
                doTempMap(8,part_style_record[8],assorted_map_number);
            }
		}
		if(map_sub_type == 4){//袖口+领子拼色
            if(cur_shirt_long =1){//长袖
                doTempMap(8,part_style_record[8],assorted_map_number);
            }else{
                alert('对不起，短袖口暂时不支持拼色');
                return ;//短袖无拼色
            }
            doTempMap(5,part_style_record[5],assorted_map_number);//上领
            doTempMap(6,part_style_record[6],assorted_map_number);//下领

		}
    }
    window.changeMapSub =function (map_sub_type,map_sub_num,is_self_fabric) {  //map_sub_type 只有三种拼色类型 领子 袖口 领子+袖口
        if ($(this).find('i').hasClass('icon-shouqing1')) {
            //如果为售罄面料就不执行操作
            return false;
        }
        //取当前选择拼色信息
        var assorted_map_number =map_sub_num; //贴图号（拼色面料号）
        changesubmodel(map_sub_type,assorted_map_number, is_self_fabric);
    };
    //更换性别加载模型
    window.changeSexModel = function changesexmodel(sex) {
        var map_files = 1;//更新为初始面料
        if(cur_shirt_long == 2){
            window.issex = 9;
        }
        if (sex == 1) {
            model_sex = 'Man';
        }
        if(sex == 2){
            model_sex = 'Woman';
        }
        // cur_shirt_long ;
        alert(part_number);
        alert(style_number);
        if (2   == 1) {
            alert('请等待模型加载完毕');
            return false;
        }
        // alert(window.model_set_num);
         // model_set_num = 3;
        // 更改性别参数初始化加载
        init_scene();
    };
    function init_model() {
        //load_obj_mtl('model_hangers',2,0);  //加载衣架模型

        for (var i = 1; i <= cur_model_max_count; i++) {   //加载商品模型各部位
            style_number = 1;
            if(window.issex == 9 && (i==8 || i==10 || i==11)){//男女装长短袖切换
                continue;
            }else{
                if ((i == 9 || i == 7) && window.issex != 9) {  //初始时 9加载长袖不加载短袖  7不加载口袋
                    continue;
                } else if (i == 10) {
                    style_number = 2;
                }
            }
            part_number = i;
            console.log('part'+part_number);
            load_obj_mtl(part_number + '-' + style_number, 1, 0);
            //加载商品模型对应的扣子/扣眼/扣线
            load_button_all(0);
        }

        //面料
//        var fa_id  = $(".wrapperm1").find('.icon-gouxuankuangzhongdegouhao').parent().data('map-number');
//        changeallmodel(fa_id, 2, 2);
        //所有对象初始化后 默认选中项为
//            part_number = 1;
//            style_number = 1;
    }

    //二次设计（有基础设计的情况）（扣子相关部位记录只是方便切换，初始时直接取部位就可以了，扣线扣眼记录又与扣子同步，只有扣眼模型号不同）
    //法式扣和普通扣公用钮扣变量
    function basic_model() {
        //上次设计保存时的数据对照说明
//                all_data[0] = part_style_record; //商品当前所有部位款式记录
//                all_data[1] = goods_map_number;  //商品各部位对应编号下的贴图编号(面料号)
//                all_data[2] = button_model_number;  //钮扣模型编号
//                all_data[3] = button_material_number;  //钮扣材质编号
//                all_data[4] = button_map_number;  //钮扣贴图编号(钮扣号)
//                all_data[5] = button_hole_model_number;  //扣眼模型编号
//                all_data[6] = cur_shirt_long;  //长短袖
//                all_data[7] = cur_show_pocket;  //有无口袋

        ////////模拟数据start-------------------------
        //初始时扣子相关的记录就是对应的部位记录
        again_design_data[0] = {1: 1, 2: 1, 3: 1, 4: 2, 5: 7, 6: 1, 7: 3, 8: 7, 10: 3, 11: 3, 12: 1, 13: 1};//商品当前所有部位款式记录
        again_design_data[1] = {1: 40, 2: 4, 3: 4, '4-1': 4, '4-2': 4, '5-1': 4, '5-2': 4, '6-1': 4, '6-2': 4, 7: 4, '8-1': 4, '8-2': 4, '9-1': 4, '9-2': 4, 10: 4, 11: 4, 12: 4, 13: 4};//商品各部位对应编号下的贴图编号(面料号)
        again_design_data[2] = 2;  //钮扣模型编号
        again_design_data[3] = 1; //钮扣材质编号
        again_design_data[4] = 1;  //钮扣贴图编号(钮扣号)
        again_design_data[5] = 1; //扣眼模型编号
        again_design_data[6] = 1; //长短袖
        again_design_data[7] = 2;  //有无口袋
        ////////模拟数据end-------------------------

        part_style_record = again_design_data[0];   //模型部位款式
        goods_map_number = again_design_data[1];  //模型部位款式对应贴图
        button_map_number = again_design_data[2];  //钮扣模型编号
        button_model_number = again_design_data[3];  //钮扣材质编号
        button_material_number = again_design_data[4];  //钮扣贴图编号(钮扣号)
        button_hole_model_number = again_design_data[5];  //扣眼模型编号
        cur_shirt_long = again_design_data[6];   //长短袖
        cur_show_pocket = again_design_data[7];   //有无口袋

        for (var i = 1; i <= cur_model_max_count; i++) {   //加载商品模型各部位
            if (cur_shirt_long == 1) {  //长袖
                if (i == 9) {  //初始时加载长袖不加载短袖
                    continue;
                }
            } else {
                if (i == 8 || i == 10 || i == 11) {  //初始时加载短袖不加载长袖
                    continue;
                }
            }
            if (cur_show_pocket == 1) {
                if (i == 7) {  //无口袋时不加载口袋
                    continue;
                }
            }
            if (i == 5) {  //5上领先不加载，先判断6，因为即便是几个没有上领的款式，记录也有之前的信息，所以以下领为主判断
                continue;
            }
            if (i == 6) {
                if (part_style_record[6] > 1) {
                    part_number = 6;
                    style_number = part_style_record[6];
                    load_obj_mtl(part_number + '-' + style_number, 1, 1);
                    //加载商品模型对应的扣子/扣眼/扣线
                    load_button_all(1);
                } else {
                    part_number = 5;
                    style_number = part_style_record[5];
                    load_obj_mtl(part_number + '-' + style_number, 1, 1);
                    //加载商品模型对应的扣子/扣眼/扣线
                    load_button_all(1);
                    part_number = 6;
                    style_number = 1;
                    load_obj_mtl(part_number + '-' + style_number, 1, 1);
                    //加载商品模型对应的扣子/扣眼/扣线
                    load_button_all(1);
                }
            } else {
                part_number = i;
                style_number = part_style_record[part_number];
                load_obj_mtl(part_number + '-' + style_number, 1, 1);
                //加载商品模型对应的扣子/扣眼/扣线
                load_button_all(1);
            }
        }
    }

    //旋转场景
    function rotation_scene() {
        status_rotation = 1;
    }

    //初始轨道控制器
    function init_orbit() {
        //轨道
        orbitControls = new THREE.OrbitControls(camera, canvas);
        //轨道API
        orbitControls.center = new THREE.Vector3(0,0,0);
        orbitControls.userZoom = true;
        orbitControls.userZoomSpeed = 0.6;
        orbitControls.userRotate = true;
        orbitControls.userRotateSpeed = 2.0;
        orbitControls.userPan = true;
        orbitControls.userPanSpeed = 0.05;
        orbitControls.autoRotate = false;//自动旋转
        orbitControls.autoRotateSpeed = 2.0; // 30 seconds per round when fps is 60
        orbitControls.minPolarAngle = Math.PI / 2; // radians  控制上下旋转角度
        orbitControls.maxPolarAngle = Math.PI / 2; // radians 控制上下旋转角度
        orbitControls.minDistance = 2; //缩放最近距离
        orbitControls.maxDistance = 10;  //控制缩放最远距离
           // orbitControls.keys = { LEFT: 37, UP: 38, RIGHT: 39, BOTTOM: 40 };

        //轨迹球--计时
        clock = new THREE.Clock();
    }

    //初始化
    function init_scene() {
        //组容器
        group = new THREE.Object3D();
        //环境光颜色
        var ambientColor = 0xffffff;
        //GUI
//        guicontrols = new function(){
////                this.ambientColor = ambientColor;  //环境光
//            this.dirlight_x = 100;
//            this.dirlight_y = 100;
//            this.dirlight_z = 17;
//        };
//        var gui = new dat.GUI();
//        gui.add(guicontrols, 'dirlight_x',0,100);
//        gui.add(guicontrols, 'dirlight_y',0,100);
//        gui.add(guicontrols, 'dirlight_z',0,100);

//            gui.add(controles, 'light_x').onChange(function(e){
//                ambientColor = new THREE.Color(e);
//            });
        //渲染器
        renderer = new THREE.WebGLRenderer({
            canvas: canvas, //画布
            antialias: true, //是否开启反锯齿
            //precision:"mediump",    //着色精度选择  highp/mediump/lowp  (精度高了运行平台可能不支持，低了图形可能变形或失真，低的情况包括3D导出时)
            alpha: true, //是否可以设置背景色透明
//            premultipliedAlpha:false,
//            stencil:false,
            preserveDrawingBuffer: true //是否保存绘图缓冲
//            maxLights:1           //maxLights:最大灯光数
        });
//          renderer.setSize(canvas.width,canvas.height);    //设置渲染器的高宽
        renderer.setClearColor(0xffffff, 0); //画布颜色和透明度
        scene = new THREE.Scene();  //创建场景
        //相机
        camera = new THREE.PerspectiveCamera(50, canvas.width / canvas.height, 0.1, 10000);
        camera.position.set(0, 0, 10); //相机位置
        camera.lookAt(new THREE.Vector3(0, 0, 0));  //lookAt()设置相机所看的位置
        scene.add(camera); //把相机添加到场景中

        ambient = new THREE.AmbientLight(ambientColor);
        scene.add(ambient);

        dirlight1 = new THREE.DirectionalLight(0xffffff);//光源颜色
        dirlight1.position.set(-29, 101, 80);//光源位置
        dirlight1.intensity = 0.8;
//            light.position.set(20, 10, 5);//光源位置
        dirlight2 = new THREE.DirectionalLight(0xffffff);//光源颜色
        dirlight2.position.set(15, -7, -29);//光源位置
        dirlight2.intensity = 0.8;
        scene.add(dirlight1);//光源添加到场景中
        scene.add(dirlight2);//光源添加到场景中

        if (again_design_type == 1) {
            //二次设计载入模型
            basic_model();
        } else {
            //初始载入模型
            init_model();
        }
        //初始轨道控制器
        init_orbit();
        //初始tween动画
        //    init_tween();
        //创建鼠标射线
        //    window.addEventListener('click',
        //            create_ray
        //    );

        draw();
    }

    //初始化补间动画（放大显示部位）
    //function init_tween(){
    //    tween = new TWEEN.Tween({camera_x: camera.position.x, camera_y: camera.position.y, camera_z: camera.position.z})
    //            .to({camera_x: 0, camera_y: 0, camera_z: 0}, 5000)
    //            .easing(TWEEN.Easing.Sinusoidal.InOut)
    //            .onStart(function(){
    //
    //            })
    //            .onUpdate(function(){
    //                camera.position.set();
    //            })
    //    ;
    //}

    //Web开始渲染
    function draw() {
        //        if(status_load == 1 && status_rotation == 1){  //模型已成功载入 && 开始旋转
        //            group.rotation.y += 0.01;//添加动画
        //            if (group.rotation.y > Math.PI * 2) {
        //                group.rotation.y -= Math.PI * 2;
        //            }
        //        }

        //dirlight.position.set(guicontrols.dirlight_x, guicontrols.dirlight_y, guicontrols.dirlight_z);
        var delta = clock.getDelta();
        orbitControls.update(delta);
//            TWEEN.update();
        renderer.render(scene, camera);//调用WebGLRenderer的render函数刷新场景
        requestAnimationFrame(draw);
    }


//        function create_ray(event){
//            var Sx = event.clientX;//鼠标单击位置横坐标
//            var Sy = event.clientY;//鼠标单击位置纵坐标
//            //屏幕坐标转标准设备坐标
//            var x = ( Sx / canvas.width ) * 2 - 1;//标准设备横坐标
//            var y = -( Sy / canvas.height ) * 2 + 1;//标准设备纵坐标
//
//            //        var rect = ev.target.getBoundingClientRect(); //事件源
//            //        var x = ((Sx - rect.left) - canvas.width/2) / (canvas.width/2);//标准设备横坐标
//            //        var y = (canvas.height/2 - (y - rect.top)) / (canvas.height/2);//标准设备纵坐标
//
//            var standardVector  = new THREE.Vector3(x, y, 0.5);//标准设备坐标
//            //标准设备坐标转世界坐标
//            var worldVector = standardVector.unproject(camera);
//            //射线投射方向单位向量(worldVector坐标减相机位置坐标)
//            var ray = worldVector.sub(camera.position).normalize();
//            //创建射线投射器对象
//            var raycaster = new THREE.Raycaster(camera.position, ray);
//            //返回射线选中的对象
//            console.log(scene.children[4]['children']);return;
//
//            var intersects = raycaster.intersectObjects(
//                    [scene.children[4]['children'][0],
//                        scene.children[4]['children'][1],
//                        scene.children[4]['children'][2],
//                        scene.children[4]['children'][3],
//                        scene.children[4]['children'][4],
//                        scene.children[4]['children'][5],
//                        scene.children[4]['children'][6],
//                        scene.children[4]['children'][7],
//                        scene.children[4]['children'][8],
//                        scene.children[4]['children'][9],
//                        scene.children[4]['children'][10],
//                        scene.children[4]['children'][11],
//                        scene.children[4]['children'][12],
//                        scene.children[4]['children'][13],
//                        scene.children[4]['children'][101],
//                        scene.children[4]['children'][102],
//                        scene.children[4]['children'][103],
//                        scene.children[4]['children'][104],
//                        scene.children[4]['children'][105],
//                        scene.children[4]['children'][106],
//                        scene.children[4]['children'][107],
//                        scene.children[4]['children'][108],
//                        scene.children[4]['children'][121],
//                        scene.children[4]['children'][122],
//                        scene.children[4]['children'][123],
//                        scene.children[4]['children'][124],
//                        scene.children[4]['children'][125],
//                        scene.children[4]['children'][126],
//                        scene.children[4]['children'][127],
//                        scene.children[4]['children'][128],
//                        scene.children[4]['children'][201]],
//                    true);
//            if (intersects.length > 0) {
//                console.log(intersects[0].object.name);
////                intersects[0].object.material.transparent = true;
////                intersects[0].object.material.opacity = 0.1;
//                cur_selected = intersects[0];
//                //way1 根据点击位置移动相机（还需要设定一定公式来计算相机对应的位置）
////                camera.position.set(cur_selected['point']['x'], cur_selected['point']['y'], 10.0); //相机位置
//
//                //way2 根据点击的部位，把相机移动到默认位置
////                console.log(intersects[0].object.parent.name);
//                var obj_part_number = intersects[0].object.parent.name.split("-");
//                part_number_position(obj_part_number[0]);
//            }
//        }


    //返回：滚轮方向 1：向上 -1：向下
//        var scrollFunc=function(e){
//            e=e || window.event;
//            var t1=document.getElementById("wheelDelta");
//            var t2=document.getElementById("detail");
//            if(e.wheelDelta){//IE/Opera/Chrome
//                t1.value=e.wheelDelta;
//                console.log(camera.position);
//            }else if(e.detail){//Firefox
//                t2.value=e.detail;
//                console.log(camera.position);
//            }
//        };
//        /*注册事件*/
//        if(document.addEventListener){
//            document.addEventListener('DOMMouseScroll',scrollFunc,false);
//        }//W3C
//        window.onmousewheel=document.onmousewheel=scrollFunc;//IE/Opera/Chrome/Safari

    $('#switch_show').click(function () {
        orbitControls.enabled = true;
        camera = temp_camera;
        renderer.render(scene, camera);//调用WebGLRenderer的render函数刷新场景
    });
    //根据部位号定位相机
    function part_number_position(cur_selected_part_number) {
        var temp_x = 0;
        var temp_y = 0;
        var temp_z = 0;

//            console.log(camera.position);
//            return;
        //定位于刺绣部位 1先禁用orbitControles才行  2保留当前相机设置 3重置相机
        orbitControls.enabled = false;
        temp_camera = camera;
        camera = new THREE.PerspectiveCamera(50, canvas.width / canvas.height, 0.1, 10000);
        renderer.render(scene, camera);//调用WebGLRenderer的render函数刷新场景

        switch (cur_selected_part_number) { //因为点击模型具体某个点来进行移动相机，显示位置会变动很大，并且还没想好对应的放大后相机的位置公式，以及是否符合需求，所以先以默认值
            case 1:  //胸口
                temp_x = 1.2343713673841454;
                temp_y = 1.2888408484920417;
                temp_z = 3.3138012995461805;
                break;
            case 2:  //领口
                temp_x = 0.2564026218186083;
                temp_y = 2.793323427130794;
                temp_z = 3.5075913587175123;
                break;
            case 3:  //左袖
                temp_x = 1.732113657370058;
                temp_y = -1.7803036119310227;
                temp_z = 3.4302526204807835;
                break;
            case 4:  //右袖
                temp_x = -1.28236818309793366;
                temp_y = -0.6837029768343295;
                temp_z = 3.4852122094587474;
                break;
            case 5:  //里襟
                temp_x = 0.48290861899128346;
                temp_y = -1.7869902011694159;
                temp_z = 3.1211687285597502;
                break;
            case 6:  //下摆
                temp_x = -0.9899433551614404;
                temp_y = -1.7535572549774434;
                temp_z = 3.263877124232275;
                break;
            default :  //胸口
                temp_x = 1.2343713673841454;
                temp_y = 1.2888408484920417;
                temp_z = 3.3138012995461805;
                break;
        }
//            tween.start();
//            if(status_scale != cur_selected_part_number){

        TweenMax.to(camera.position, 1, {
            x: temp_x,
            y: temp_y,
            z: temp_z,
            ease: Expo.easeInOut,
            onComplete: function () {
//                        status_scale = cur_selected_part_number;
            }
        });
//            }
    }

    //画布贴图(刺绣 输入文字)
    function create_canvas_map(emb_content, emb_font, emb_color, part_number) {
        var emb_canvas = document.getElementById("embCanvas");
        var context = emb_canvas.getContext("2d");
        //每次绘制先清空画布
        context.clearRect(0, 0, emb_canvas.width, emb_canvas.height);

        context.fillStyle = emb_color;
        context.font = "24px " + emb_font;  //注意空格
        context.textBaseline = 'top';

        //为了展示效果处理文字内容 加空格
//            var temp_arr = emb_content.split("");
//            $.each(temp_arr,function(key,value){
//                temp_arr[key] = value + ' ';
//            });
//            emb_content = temp_arr.join("");

        switch (part_number) {
            case 121:
//                    context.fillText(emb_content,1000,230);  //胸口
//                context.fillText(emb_content,1600,388);  //胸口
                context.fillText(emb_content, 1511, 682);  //胸口
                break;
            case 122:
                context.fillText(emb_content, 917, 2246);  //领口
                break;
            case 123:
                context.fillText(emb_content, 917, 2246);  //左长袖
                break;
            case 124:
                context.fillText(emb_content, 917, 2246);  //左短袖
                break;
            case 125:
                context.fillText(emb_content, 917, 2246);  //右长袖
                break;
            case 126:
                context.fillText(emb_content, 917, 2246); //右短袖
                break;
            case 127:
//                    context.fillText(emb_content,917,2246);  //里襟（需要旋转内容）
                context.save();
                context.translate(830, 2216);//将画布坐标系原点移至中心
                context.rotate(360 * Math.PI / 180);
                context.fillText(emb_content, 0, 0);  //胸口
                context.restore();
                break;
            case 128:
//                    context.fillText(emb_content,580,1560);  //腰部
                context.fillText(emb_content, 917, 2246);
                break;
            case 129:
//                    context.fillText(emb_content,580,1560);  //领尖层
                context.fillText(emb_content, 917, 2246);
                break;
            case 130:
//                    context.fillText(emb_content,580,1560);  //口袋层
                context.fillText(emb_content, 1511, 682);  //胸口
                break;
            default :
                alert('绣花显示不正确！');
                break;
        }

        var canvasMap = new THREE.Texture(emb_canvas);
        canvasMap.needsUpdate = true;
        return canvasMap;
    }
    function create_canvas_map1(emb_content, emb_font, emb_color, part_number) {
//        alert(part_number)
        var emb_canvas = document.getElementById("embCanvas1");
        var context = emb_canvas.getContext("2d");
        //每次绘制先清空画布
        context.clearRect(0, 0, emb_canvas.width, emb_canvas.height);

        context.fillStyle = emb_color;
        context.font = "24px " + emb_font;  //注意空格
        context.textBaseline = 'top';

        //为了展示效果处理文字内容 加空格
//            var temp_arr = emb_content.split("");
//            $.each(temp_arr,function(key,value){
//                temp_arr[key] = value + ' ';
//            });
//            emb_content = temp_arr.join("");

        switch (part_number) {
            case 121:
//                    context.fillText(emb_content,1000,230);  //胸口
//                context.fillText(emb_content,1600,388);  //胸口
                context.fillText(emb_content, 1511, 682);  //胸口
                break;
            case 122:
                context.fillText(emb_content, 917, 2246);  //领口
                break;
            case 123:
                context.fillText(emb_content, 917, 2246);  //左长袖
                break;
            case 124:
                context.fillText(emb_content, 917, 2246);  //左短袖
                break;
            case 125:
                context.fillText(emb_content, 917, 2246);  //右长袖
                break;
            case 126:
                context.fillText(emb_content, 917, 2246); //右短袖
                break;
            case 127:
//                    context.fillText(emb_content,917,2246);  //里襟（需要旋转内容）
                context.save();
                context.translate(830, 2216);//将画布坐标系原点移至中心
                context.rotate(360 * Math.PI / 180);
                context.fillText(emb_content, 0, 0);  //胸口
                context.restore();
                break;
            case 128:
//                    context.fillText(emb_content,580,1560);  //腰部
                context.fillText(emb_content, 917, 2246);
                break;
            case 129:
//                    context.fillText(emb_content,580,1560);  //领尖层
                context.fillText(emb_content, 917, 2246);
                break;
            case 130:
//                    context.fillText(emb_content,580,1560);  //口袋层
                context.fillText(emb_content, 1511, 682);  //胸口
                break;
            default :
                alert('绣花显示不正确！');
                break;
        }

        var canvasMap = new THREE.Texture(emb_canvas);
        canvasMap.needsUpdate = true;
        return canvasMap;
    }

    //选择绣花文字或绣花图片时设置 emb_type
    window.changeembtype=function changeembtype (num) {
        emb_type = num;
        if (emb_type == 999) {  //减的情况
            emb_style_type = '';
            emb_content = '';
            if(status_enabled_emb_pri == 1) {
                //绣花1
                status_enabled_emb_pri = 0;
            }else if (status_enabled_emb_pri == 3) {
                //都有
                status_enabled_emb_pri = 7;
            }else if (status_enabled_emb_pri == 5) {
                //绣花1和绣花2  
                status_enabled_emb_pri = 4;
            }else if (status_enabled_emb_pri == 6) {
                //绣花1和印花
                status_enabled_emb_pri = 2;
            }
            for (var i = 101; i <= 130; i++) {  //清除所有存在的刺绣层
                for (var j = 1; j <= cur_model_style_max_count; j++) {
                    if (scene.getObjectByName(i + '-' + j) != undefined) {
                        scene.children[4].remove(scene.getObjectByName(i + '-' + j));  //ps: remove只能删除对象的直接子级对象
                    }
                }
            }
            THREE.Cache.clear();  //清除缓存
        }
    };
    //绣花2，无绣花
    window.changeembtype1=function () {
        emb_type1 = $(this).data('num');
        if (emb_type1 == 999) {  //减的情况
            emb_style_type1 = '';
            emb_content1 = '';
            if (status_enabled_emb_pri == 4) {
                //绣花1
                status_enabled_emb_pri = 0;
            } else if (status_enabled_emb_pri == 3) {
                //都有
                status_enabled_emb_pri = 6;
            }else if (status_enabled_emb_pri == 5) {
                //绣花1和绣花2  
                status_enabled_emb_pri = 1;
            }else if (status_enabled_emb_pri == 7) {
                //绣花2和印花
                status_enabled_emb_pri = 2;
            }
            for (var i = 151; i <= 180; i++) {  //清除所有存在的刺绣层
                for (var j = 1; j <= cur_model_style_max_count; j++) {
                    if (scene.getObjectByName(i + '-' + j) != undefined) {
                        scene.children[4].remove(scene.getObjectByName(i + '-' + j));  //ps: remove只能删除对象的直接子级对象
                    }
                }
            }
            THREE.Cache.clear();  //清除缓存
        }
    };

    //emb_type1 刺绣功能1-输入刺绣内容
    $('#emb_content').blur(function () {
        emb_content = $.trim($(this).val());
        // console.log(emb_content);
        if (emb_content != null && emb_content != '' && RegeMatch(emb_content) && judge_len_10(emb_content)) {
            updata_emb_pri_st(1);
            show_emb();
        } else {
            $(this).val('');
            //个性字母为空的时候，清除绣花字母层
            if (emb_type == 1) {
                if (emb_type == 1) {
                    emb_style_type = '';
                    emb_content = '';
                    if (status_enabled_emb_pri == 1) {
                        //绣花1
                        status_enabled_emb_pri = 0;
                    } else if (status_enabled_emb_pri == 3) {
                        //都有
                        status_enabled_emb_pri = 7;
                    }else if (status_enabled_emb_pri == 5) {
                        //绣花1和绣花2  
                        status_enabled_emb_pri = 4;
                    }else if (status_enabled_emb_pri == 6) {
                        //绣花1和印花
                        status_enabled_emb_pri = 2;
                    }
                    
                    for (var i = 101; i <= 130; i++) {  //清除所有存在的刺绣层
                        for (var j = 1; j <= cur_model_style_max_count; j++) {
                            if (scene.getObjectByName(i + '-' + j) != undefined) {
                                scene.children[4].remove(scene.getObjectByName(i + '-' + j));  //ps: remove只能删除对象的直接子级对象
                            }
                        }
                    }
                    THREE.Cache.clear();  //清除缓存
                }
            }
        }
    });
    //绣花2 文字输入
    $('#emb_content1').blur(function () {
        emb_content1 = $.trim($(this).val());
        if (emb_content1 != null && emb_content1 != '' && RegeMatch(emb_content1) && judge_len_10(emb_content1)) {
            updata_emb_pri_st(4);
            show_emb1();
        } else {
            $(this).val('');
            //个性字母为空的时候，清除绣花字母层
            if (emb_type1 == 1) {
                if (emb_type1 == 1) {
                    emb_style_type1 = '';
                    emb_content1 = '';
                    if (status_enabled_emb_pri == 4) {
                        //绣花1
                        status_enabled_emb_pri = 0;
                    } else if (status_enabled_emb_pri == 3) {
                        //都有
                        status_enabled_emb_pri = 6;
                    }else if (status_enabled_emb_pri == 5) {
                        //绣花1和绣花2  
                        status_enabled_emb_pri = 1;
                    }else if (status_enabled_emb_pri == 7) {
                        //绣花2和印花
                        status_enabled_emb_pri = 2;
                    }
                    for (var i = 151; i <= 180; i++) {  //清除所有存在的刺绣层
                        for (var j = 1; j <= cur_model_style_max_count; j++) {
                            if (scene.getObjectByName(i + '-' + j) != undefined) {
                                scene.children[4].remove(scene.getObjectByName(i + '-' + j));  //ps: remove只能删除对象的直接子级对象
                            }
                        }
                    }
                    THREE.Cache.clear();  //清除缓存
                }
            }
        }
    });

    //输入判断 输入长度 中文算两个 字母一个
    function judge_len_10(str_word) { //10个中文以内
        var word_num = 10;
        var sum = 0;
        for (var i = 0; i < str_word.length; i++) {
            sum += ((str_word.charCodeAt(i) >= 0) && (str_word.charCodeAt(i) <= 255)) ? 1 : 2;
        }
        if (sum > word_num * 2) {
            alert("设计名称不能超过" + word_num + "个中文或" + word_num * 2 + "个英文！");
            return false;
        } else {
            return true;
        }
    }
    //输入判断 过滤特殊字符
    function RegeMatch(str_word) {
        var pattern = new RegExp("[~'!@#$%^&*()-+_=:]");
        if (pattern.test(str_word)) {
            alert("请输入中文或英文字母！");
            return false;
        } else {
            return true;
        }
    }

    //emb_type1/2 刺绣功能2-选择刺绣部位
    window.changeembpart=function (part) {
        var temp_val =part;
        alert(part);
        alert(emb_type);
        //取后台数据对应下标 就不用判断了
        if (temp_val > 0 && temp_val < 9) {
            emb_part_number = temp_val;
        } else {
            emb_part_number = 1;
        }
//默认情况下，只选择部位而没有选择内容或者生肖星座样色时，不显示绣花。
        if (emb_type == 1 && emb_content) {
            updata_emb_pri_st(1);
            show_emb();
        } else if ((emb_type == 2 || emb_type == 3) && emb_style_type) {
            updata_emb_pri_st(1);
            show_emb();
        }
        //part_number_position(emb_part_number);
    };
    //绣花2 部位
    window.changeembpart1=function (type) {
        var temp_val = type;
        //取后台数据对应下标 就不用判断了
        if (temp_val > 0 && temp_val < 9) {
            emb_part_number1 = temp_val;
        } else {
            emb_part_number1 = 1;
        }
//默认情况下，只选择部位而没有选择内容或者生肖星座样色时，不显示绣花。
        if (emb_type1 == 1 && emb_content1) {
            updata_emb_pri_st(4);
            show_emb1();
        } else if ((emb_type1 == 2 || emb_type1 == 3) && emb_style_type1) {
            updata_emb_pri_st(4);
            show_emb1();
        }
        //part_number_position(emb_part_number);
    };

    //emb_type1/2 刺绣功能3-选择刺绣颜色
    window.changeembcolor=function (num,color) {
        updata_emb_pri_st(1);
        var temp_val = num;
        emb_color = color;
        emb_color_number = temp_val;  //刺绣图片编号
        alert(emb_color);
        alert(emb_color_number);
        //默认情况下，只选择颜色而没有选择内容或者生肖星座样色时，不显示绣花。
        if (emb_type == 1 && emb_content) {
            show_emb();
        } else if ((emb_type == 2 || emb_type == 3) && emb_style_type) {
            show_emb();
        }
    };
    //绣花2颜色
    window.changeembcolor1=function (num,color) {
        updata_emb_pri_st(4);
        var temp_val = num;
        emb_color1 = color;
        emb_color_number1 = temp_val;  //刺绣图片编号
        //默认情况下，只选择颜色而没有选择内容或者生肖星座样色时，不显示绣花。
        if (emb_type1 == 1 && emb_content1) {
            show_emb1();
        } else if ((emb_type1 == 2 || emb_type1 == 3) && emb_style_type1) {
            show_emb1();
        }
    };
    //emb_type1 刺绣功能4-选择刺绣字体(风格)
    window.changeembstyle=function (num,font) {
        updata_emb_pri_st(1);
        var temp_val = num;
        emb_content = $('#emb_content').val();
        //取后台数据对应下标 就不用判断了
        emb_font = font;
        if (emb_content != null && emb_content != '' && RegeMatch(emb_content) && judge_len_10(emb_content)) {
            show_emb();
        } else {
            $(this).val('');
        }

        //console.log(emb_font);
        //show_emb();
        //alert('error2');

    };
    //绣花2风格
    window.changeembstyle=function () {
        updata_emb_pri_st(4);
        var temp_val = $(this).data('emb-font-number');
        emb_content1 = $('#emb_content1').val();
        //取后台数据对应下标 就不用判断了
        emb_font1 = $(this).data('emb-font');
        if (emb_content1 != null && emb_content1 != '' && RegeMatch(emb_content1) && judge_len_10(emb_content1)) {
            show_emb1();
        } else {
            $(this).val('');
        }

        //console.log(emb_font);
        //show_emb();
        //alert('error2');

    };

    //emb_type2 刺绣功能5-选择刺绣图片
    window.changeembsx=function (type,number) {
        updata_emb_pri_st(1);
        emb_style_type = type;  //非文字 绣花图片款式类型
        emb_style_number = number; //非文字 绣花图片款式编号
        // console.log(emb_style_type);
        // console.log(emb_style_number);
        show_emb();
    };
    //绣花2 图片
    window.changeembsx1=function (type,number) {
        updata_emb_pri_st(4);
        emb_style_type1 = type;  //非文字 绣花图片款式类型
        emb_style_number1 = number; //非文字 绣花图片款式编号
        show_emb1();
    };
    //更新刺绣、印花状态 （只考虑加的情况）
    function  updata_emb_pri_st(type) {
        switch (status_enabled_emb_pri) {
            case 0:
                status_enabled_emb_pri = type;
                break;
            case 1:
                if (type == 2 && emb_part_number != 1 && emb_part_number != 6) {  //如果刺绣了胸口/腰部，再选择印花无效（胸口只能显示一种）
                    status_enabled_emb_pri = 6;
                }
                
                if (type == 4 && emb_part_number1 != emb_part_number) {  //绣花2不能与绣花1同部位
                    status_enabled_emb_pri = 5;
                }
                break;
            case 2:
                if (type == 1 && emb_part_number != 1 && emb_part_number != 6) {  //如果存在印花了，再刺绣胸口花无效（胸口只能显示一种）
                    status_enabled_emb_pri = 6;
                }
                if (type == 4 && emb_part_number1 != 1 && emb_part_number1 != 6) {  //如果存在印花了，绣花2不能胸口花无效（胸口只能显示一种）
                    status_enabled_emb_pri = 7;
                }
                break;
            case 3:  //已经是存在印花 刺绣的情况，如果再选择另一种到胸口的话，需要页面效果来限制操作。
//                    if((type == 1 || type == 2) && emb_part_number != 1){  //如果存在印花了，再刺绣胸口花无效（胸口只能显示一种）
//                        status_enabled_emb_pri = 3;
//                    } else  if(type == 1 && emb_part_number != 1){  //如果存在印花了，再刺绣胸口花无效（胸口只能显示一种）
//                        status_enabled_emb_pri = 3;
//                    }
//                    status_enabled_emb_pri = 3;
                break;
            case 4:
                if (type == 2 && emb_part_number1 != 1 && emb_part_number1 != 6) {  //如果刺绣2了胸口/腰部，再选择印花无效（胸口只能显示一种）
                    status_enabled_emb_pri = 7;
                }
                
                if (type == 1 && emb_part_number1 != emb_part_number) {  //绣花2不能与绣花1同部位
                    status_enabled_emb_pri = 5;
                }
                break;
            case 5:
                if (type == 2 && (emb_part_number != 1 && emb_part_number != 6) && (emb_part_number1 != 1 && emb_part_number1 != 6)) {  //如果刺绣1和刺绣2了胸口/腰部，再选择印花无效（胸口只能显示一种）
                    status_enabled_emb_pri = 3;
                }
                break;
            case 6:
                if (type == 4 && (emb_part_number1 != 1 && emb_part_number1 != 6) && emb_part_number1!=emb_part_number) {  //如果存在印花了，绣花2不能胸口花无效（胸口只能显示一种）,绣花1和绣花2部位不能相同
                    status_enabled_emb_pri = 3;
                }
                break;
            case 7:
                if (type == 1 && (emb_part_number != 1 && emb_part_number != 6) && emb_part_number1!=emb_part_number) {  //如果存在印花了，绣花2不能胸口花无效（胸口只能显示一种）,绣花1和绣花2部位不能相同
                    status_enabled_emb_pri = 3;
                }
                break;
        }
    }
    //生成刺绣
    function show_emb() {
        if (status_enabled_emb_pri != 1 && status_enabled_emb_pri != 3 && status_enabled_emb_pri != 5 && status_enabled_emb_pri != 6) {  //没启用刺绣或者刺绣印花都没启用
            return false;
        }
        if ((status_enabled_emb_pri == 3 && status_enabled_emb_pri == 6) && (emb_part_number == 1 || emb_part_number == 6)) {  //都启用但当前再次选择导致胸口/里襟显示一种以上效果就当前选择失效
            return false;
        }
        if ((status_enabled_emb_pri == 3 && status_enabled_emb_pri == 5) &&  emb_part_number == emb_part_number1) {  //都启用但当前再次选择导致胸口/里襟显示一种以上效果就当前选择失效
            return false;
        }
        switch (emb_part_number) {
            case 1:   //胸口层
                if (emb_type > 1) {
                    if (cur_show_pocket == 1) { //1无口袋绣在身片上
                        part_number = 101;
                    } else {  //有口袋绣在口袋上
                        part_number = 110;
                    }
                } else {
                    if (cur_show_pocket == 1) { //1无口袋绣在身片上
                        part_number = 121;
                    } else {  //有口袋绣在口袋上
                        part_number = 130;
                    }
                }
                style_number = 1; //只有一种款式 原12
//                    emb_map.repeat.set(28,30);  //但不同部位需要不同平铺大小
                break;
            case 2:   //领口层（下领里）
                if (emb_type > 1) {
                    part_number = 102;
                } else {
                    part_number = 122;
                }
                style_number = part_style_record[6]; //对应的原模型部位编号 6
                break;
            case 3:   //左袖口层
                if (cur_shirt_long == 1) {   //长袖时
                    if (emb_type > 1) {
                        part_number = 103;
                    } else {
                        part_number = 123;
                    }
                    style_number = part_style_record[8]; //对应的原模型部位编号 6
                } else {  //短袖
                    if (emb_type > 1) {
                        part_number = 104;
                    } else {
                        part_number = 124;
                    }
                    style_number = part_style_record[9]; //对应的原模型部位编号 6
                }
                break;
            case 4:   //右袖口层
                if (cur_shirt_long == 1) {   //长袖时
                    if (emb_type > 1) {
                        part_number = 105;
                    } else {
                        part_number = 125;
                    }
                    style_number = part_style_record[8]; //对应的原模型部位编号 6
                } else {  //短袖
                    if (emb_type > 1) {
                        part_number = 106;
                    } else {
                        part_number = 126;
                    }
                    style_number = part_style_record[9]; //对应的原模型部位编号 6
                }
                break;
            case 5:   //里襟层
                if (emb_type > 1) {
                    part_number = 107;
                } else {
                    part_number = 127;
                }
                style_number = 1; //只有一种款式 原3
                break;
            case 6:   //腰部层
                if (emb_type > 1) {
                    part_number = 108;
                } else {
                    part_number = 128;
                }
                style_number = 1; //只有一种款式 原13
                break;
            case 7:   //领尖层
                if (emb_type > 1) {
                    part_number = 109;
                } else {
                    part_number = 129;
                }
                style_number = 1; //只有一种款式 原13
                break;
            default :
                if (emb_type > 1) {
                    if (cur_show_pocket == 1) { //1无口袋绣在身片上
                        part_number = 101;
                    } else {  //有口袋绣在口袋上
                        part_number = 110;
                    }
                } else {
                    if (cur_show_pocket == 1) { //1无口袋绣在身片上
                        part_number = 121;
                    } else {  //有口袋绣在口袋上
                        part_number = 130;
                    }
                }
                style_number = 1; //对应的原模型部位编号
                break;
        }
        for (var i = 101; i <= 130; i++) {  //清除所有存在的刺绣层
            for (var j = 1; j <= cur_model_style_max_count; j++) {
                if (scene.getObjectByName(i + '-' + j) != undefined) {
                    scene.children[4].remove(scene.getObjectByName(i + '-' + j));  //ps: remove只能删除对象的直接子级对象
                }
            }
        }
        THREE.Cache.clear();  //清除缓存
        //加载刺绣层
        load_obj_mtl(part_number + '-' + style_number, 7, 0);
    }

    function show_emb1() {
        if (status_enabled_emb_pri != 4 && status_enabled_emb_pri != 3 && status_enabled_emb_pri != 5 && status_enabled_emb_pri != 7) {  //没启用刺绣或者刺绣印花都没启用
            return false;
        }
        if ((status_enabled_emb_pri == 3 && status_enabled_emb_pri == 7) && (emb_part_number1 == 1 || emb_part_number1 == 6)) {  //都启用但当前再次选择导致胸口/里襟显示一种以上效果就当前选择失效
            return false;
        }
        if ((status_enabled_emb_pri == 3 && status_enabled_emb_pri == 5) &&  emb_part_number == emb_part_number1) {  //都启用但当前再次选择导致胸口/里襟显示一种以上效果就当前选择失效
            return false;
        }
        switch (emb_part_number1) {
            case 1:   //胸口层
                if (emb_type1 > 1) {
                    if (cur_show_pocket == 1) { //1无口袋绣在身片上
                        part_number = 151;
                    } else {  //有口袋绣在口袋上
                        part_number = 160;
                    }
                } else {
                    if (cur_show_pocket == 1) { //1无口袋绣在身片上
                        part_number = 171;
                    } else {  //有口袋绣在口袋上
                        part_number = 180;
                    }
                }
                style_number = 1; //只有一种款式 原12
//                    emb_map.repeat.set(28,30);  //但不同部位需要不同平铺大小
                break;
            case 2:   //领口层（下领里）
                if (emb_type1 > 1) {
                    part_number = 152;
                } else {
                    part_number = 172;
                }
                style_number = part_style_record[6]; //对应的原模型部位编号 6
                break;
            case 3:   //左袖口层
                if (cur_shirt_long == 1) {   //长袖时
                    if (emb_type1 > 1) {
                        part_number = 153;
                    } else {
                        part_number = 173;
                    }
                    style_number = part_style_record[8]; //对应的原模型部位编号 6
                } else {  //短袖
                    if (emb_type1 > 1) {
                        part_number = 154;
                    } else {
                        part_number = 174;
                    }
                    style_number = part_style_record[9]; //对应的原模型部位编号 6
                }
                break;
            case 4:   //右袖口层
                if (cur_shirt_long == 1) {   //长袖时
                    if (emb_type1 > 1) {
                        part_number = 155;
                    } else {
                        part_number = 175;
                    }
                    style_number = part_style_record[8]; //对应的原模型部位编号 6
                } else {  //短袖
                    if (emb_type1 > 1) {
                        part_number = 156;
                    } else {
                        part_number = 176;
                    }
                    style_number = part_style_record[9]; //对应的原模型部位编号 6
                }
                break;
            case 5:   //里襟层
                if (emb_type1 > 1) {
                    part_number = 157;
                } else {
                    part_number = 177;
                }
                style_number = 1; //只有一种款式 原3
                break;
            case 6:   //腰部层
                if (emb_type1 > 1) {
                    part_number = 158;
                } else {
                    part_number = 178;
                }
                style_number = 1; //只有一种款式 原13
                break;
            case 7:   //领尖层
                if (emb_type1 > 1) {
                    part_number = 159;
                } else {
                    part_number = 179;
                }
                style_number = 1; //只有一种款式 原13
                break;
            default :
                if (emb_type1 > 1) {
                    if (cur_show_pocket == 1) { //1无口袋绣在身片上
                        part_number = 151;
                    } else {  //有口袋绣在口袋上
                        part_number = 160;
                    }
                } else {
                    if (cur_show_pocket == 1) { //1无口袋绣在身片上
                        part_number = 171;
                    } else {  //有口袋绣在口袋上
                        part_number = 180;
                    }
                }
                style_number = 1; //对应的原模型部位编号
                break;
        }
        for (var i = 151; i <= 180; i++) {  //清除所有存在的刺绣层
            for (var j = 1; j <= cur_model_style_max_count; j++) {
                if (scene.getObjectByName(i + '-' + j) != undefined) {
                    scene.children[4].remove(scene.getObjectByName(i + '-' + j));  //ps: remove只能删除对象的直接子级对象
                }
            }
        }
        THREE.Cache.clear();  //清除缓存
        //加载刺绣层
        load_obj_mtl(part_number + '-' + style_number, 9, 0);
    }

    //印花功能1-选择印花图片
    window.changeprint=function (type,number) {
        if (cur_model_load_st == 1) {
            alert('请等待模型加载完毕！');
            return false;
        }
        updata_emb_pri_st(2);
        print_style_number = number;
        if (print_style_number == 999) { //减的情况
            if (status_enabled_emb_pri == 2) {
                status_enabled_emb_pri = 0;
            } else if (status_enabled_emb_pri == 3) {
                status_enabled_emb_pri = 1;
            }
            if (scene.getObjectByName(201 + '-' + 1) != undefined) {
                scene.children[4].remove(scene.getObjectByName(201 + '-' + 1));  //ps: remove只能删除对象的直接子级对象
            }
            THREE.Cache.clear();  //清除缓存
        } else {
            print_type = type;
            show_print();
        }
    };

    //生成印花
    function show_print() {
        if (status_enabled_emb_pri != 2 && status_enabled_emb_pri != 3) {  //没启用印花
            return false;
        }
        if (status_enabled_emb_pri == 3 && (emb_part_number == 1 || emb_part_number == 6)) {  //都启用但当前再次选择导致胸口显示一种以上效果就当前选择失效
            return false;
        }
        if (scene.getObjectByName(201 + '-' + 1) != undefined) {
            scene.children[4].remove(scene.getObjectByName(201 + '-' + 1));  //ps: remove只能删除对象的直接子级对象
        }
        THREE.Cache.clear();  //清除缓存
        load_obj_mtl(201 + '-' + 1, 8, 0);
    }

    //切换扣子（模型/贴图）
    window.changeButton=function (model,material,map) {
        button_model_number = model;//
        button_material_number = material;//
        button_map_number = map;//
        // console.log('model'+button_model_number);
        // console.log('mate'+button_material_number);
        // console.log('map'+button_map_number);
        if (button_model_number > 100) { //法式扣
            button_part_number = 4;
            //普通扣移除
            button_style_number = button_part_style_record[button_part_number];
            if (scene.getObjectByName('button-' + button_part_number + '-' + button_style_number) != undefined) {
                scene.children[4].remove(scene.getObjectByName('button-' + button_part_number + '-' + button_style_number));  //ps: remove只能删除对象的直接子级对象
            }
            if (scene.getObjectByName('button-line-' + button_part_number + '-' + button_style_number) != undefined) {
                scene.children[4].remove(scene.getObjectByName('button-line-' + button_part_number + '-' + button_style_number));  //ps: remove只能删除对象的直接子级对象
            }
            //法式扣移除
            button_style_number = fa_button_part_style_record[button_part_number];
            if (scene.getObjectByName('fa-button-' + button_part_number + '-' + button_style_number) != undefined) {
                scene.children[4].remove(scene.getObjectByName('fa-button-' + button_part_number + '-' + button_style_number));  //ps: remove只能删除对象的直接子级对象
            }
            THREE.Cache.clear();  //清除缓存
            load_obj_mtl('fa-button-' + button_part_number + '-' + button_style_number, 4, 1);
        } else {
            //法式扣移除
            button_part_number = 4;
            button_style_number = fa_button_part_style_record[button_part_number];
            if (part_style_record[8] < 7) { //有法式扣的情况，切换普通扣，法式的不变
                if (scene.getObjectByName('fa-button-' + button_part_number + '-' + button_style_number) != undefined) {
                    scene.children[4].remove(scene.getObjectByName('fa-button-' + button_part_number + '-' + button_style_number));  //ps: remove只能删除对象的直接子级对象
                }
            }
            for (var i = 1; i <= 5; i++) {
                if (scene.getObjectByName('button-' + i + '-' + button_part_style_record[i]) != undefined) {
                    scene.children[4].remove(scene.getObjectByName('button-' + i + '-' + button_part_style_record[i]));  //ps: remove只能删除对象的直接子级对象
                }
                if (scene.getObjectByName('button-line-' + i + '-' + button_part_style_record[i]) != undefined) {
                    scene.children[4].remove(scene.getObjectByName('button-line-' + i + '-' + button_part_style_record[i]));  //ps: remove只能删除对象的直接子级对象
                }
            }
            THREE.Cache.clear();  //清除缓存
            for (var j = 1; j <= 5; j++) {
                button_part_number = j;
                switch (j) {
                    case 1:
                        button_style_number = 1;
                        if ((part_style_record[4] != 3 && part_style_record[4] != 4)) {  //为门襟时，3 4款式 为暗门襟不显示扣子
                            load_obj_mtl('button-' + button_part_number + '-' + button_style_number, 4, 1);
                            load_obj_mtl('button-line-' + '-' + button_part_number + '-' + button_style_number, 6, 1);
                        }
                        break;
                    case 2: //判断是否有上领扣
                        if (button_part_style_record[j] == 1) {
                            button_style_number = 1;
                            load_obj_mtl('button-' + button_part_number + '-' + button_style_number, 4, 1);
                            load_obj_mtl('button-line-' + button_part_number + '-' + button_style_number, 6, 1);
                        } else if (button_part_style_record[j] == 2) {
                            button_style_number = 2;
                            load_obj_mtl('button-' + button_part_number + '-' + button_style_number, 4, 1);
                            load_obj_mtl('button-line-' + button_part_number + '-' + button_style_number, 6, 1);
                        }
                        break;
                    case 3:
                        button_style_number = button_part_style_record[j];
                        load_obj_mtl('button-' + button_part_number + '-' + button_style_number, 4, 1);
                        load_obj_mtl('button-line-' + button_part_number + '-' + button_style_number, 6, 1);
                        break;
                    case 4:
                        if (cur_shirt_long == 1) {   //长袖
                            button_style_number = button_part_style_record[j];
                            if (part_style_record[8] < 7) { //有法式扣的情况，切换普通扣，法式的不变
                                load_obj_mtl('button-' + button_part_number + '-' + button_style_number, 4, 1);
                                load_obj_mtl('button-line-' + button_part_number + '-' + button_style_number, 6, 1);
                            }
                        }
                        break;
                    case 5:
                        if (cur_shirt_long == 1) {   //长袖
                            button_style_number = button_part_style_record[j];
                            load_obj_mtl('button-' + button_part_number + '-' + button_style_number, 4, 1);
                            load_obj_mtl('button-line-' + button_part_number + '-' + button_style_number, 6, 1);
                        }
                        break;
                    default :
                        alert('钮扣显示不正确！');
                        break;
                }
            }
        }

        //切换所有扣子的贴图(只是换图不换模型或材质的情况)
//            var button_map_url = 'model/formal/'+ category + '/' + Button_map + '/button_map' + '/' ;
//            var temp_map = THREE.ImageUtils.loadTexture(button_map_url + button_map_number + '.jpg');
//            for(var i=1; i<=4; i++){
//                if(scene.getObjectByName('button-'+ i + '-' + button_part_style_record[i]) != undefined){
//                   var temp_material = scene.getObjectByName('button-'+ i + '-' + button_part_style_record[i]).children[0].material;
//                    if(temp_material.map != null){
//                        temp_material.map.image = temp_map.image;
//                        temp_material.map.needsUpdate = true;
//                    }
//                }
//            }
//            cur_button_map_number = button_map_number;  //当前扣子图号
//            button_model_number = button_map_match_model_number[cur_button_map_number];  //更新
    };
    //移除扣子/扣眼/扣线
    function remove_button() {
        if (scene.getObjectByName('button-' + button_part_number + '-' + button_part_style_record[button_part_number]) != undefined) {
            scene.children[4].remove(scene.getObjectByName('button-' + button_part_number + '-' + button_part_style_record[button_part_number]));  //ps: remove只能删除对象的直接子级对象
        }
        if (scene.getObjectByName('button-hole-' + button_part_number + '-' + button_part_style_record[button_part_number]) != undefined) {
            scene.children[4].remove(scene.getObjectByName('button-hole-' + button_part_number + '-' + button_part_style_record[button_part_number]));  //ps: remove只能删除对象的直接子级对象
        }
        if (scene.getObjectByName('button-line-' + button_part_number + '-' + button_part_style_record[button_part_number]) != undefined) {
            scene.children[4].remove(scene.getObjectByName('button-line-' + button_part_number + '-' + button_part_style_record[button_part_number]));  //ps: remove只能删除对象的直接子级对象
        }
        //只有袖口且法式会有
        if (scene.getObjectByName('fa-button-' + button_part_number + '-' + fa_button_part_style_record[button_part_number]) != undefined) {
            scene.children[4].remove(scene.getObjectByName('fa-button-' + button_part_number + '-' + fa_button_part_style_record[button_part_number]));  //ps: remove只能删除对象的直接子级对象
        }
        if (scene.getObjectByName('fa-button-hole-' + button_part_number + '-' + fa_button_part_style_record[button_part_number]) != undefined) {
            scene.children[4].remove(scene.getObjectByName('fa-button-hole-' + button_part_number + '-' + fa_button_part_style_record[button_part_number]));  //ps: remove只能删除对象的直接子级对象
        }
//            alert('button-line-'+ button_part_number + '-' + button_part_style_record[button_part_number]);
    }
    //加载商品模型对应部位的扣子/扣眼/扣线
    function load_button_all(relad_st) {
        switch (part_number) {
            case 4: //门襟扣子/扣眼/扣线  首次载入时加载(切换模型不重载)
                button_part_number = 1;
                button_style_number = 1;
                button_part_number = 1;
                remove_button();
                THREE.Cache.clear();  //清除缓存
                if (style_number == 1 || style_number == 2) {
                    load_obj_mtl('button-' + button_part_number + '-' + button_style_number, 4, relad_st);
                    load_obj_mtl('button-hole-' + button_part_number + '-' + button_style_number, 5, relad_st);
                    load_obj_mtl('button-line-' + button_part_number + '-' + button_style_number, 6, relad_st);
                }
                break;
            case 5: //上领扣子/扣眼/扣线  ps: 初始模型时不会加载
                if (style_number == 6) {
                    button_part_number = 2;
                    button_style_number = 1;
                    remove_button();
                    THREE.Cache.clear();  //清除缓存
                    load_obj_mtl('button-' + button_part_number + '-' + button_style_number, 4, relad_st);
                    load_obj_mtl('button-hole-' + button_part_number + '-' + button_style_number, 5, relad_st);
                    load_obj_mtl('button-line-' + button_part_number + '-' + button_style_number, 6, relad_st);
                } else if (style_number == 7) {
                    button_part_number = 2;
                    button_style_number = 2;
                    remove_button();
                    THREE.Cache.clear();  //清除缓存
                    load_obj_mtl('button-' + button_part_number + '-' + button_style_number, 4, relad_st);
                    load_obj_mtl('button-hole-' + button_part_number + '-' + button_style_number, 5, relad_st);
                    load_obj_mtl('button-line-' + button_part_number + '-' + button_style_number, 6, relad_st);
                } else {
                    button_part_number = 2;
                    button_style_number = 1;
                    remove_button();
                    button_style_number = 2;
                    remove_button();
                    THREE.Cache.clear();  //清除缓存
                    button_part_style_record[2] = 0;
                }
                break;
            case 6: //下领扣子/扣眼/扣线
                button_part_number = 3;
                button_style_number = style_number;
                remove_button();
                load_obj_mtl('button-' + button_part_number + '-' + button_style_number, 4, relad_st);
                load_obj_mtl('button-hole-' + button_part_number + '-' + button_style_number, 5, relad_st);
                load_obj_mtl('button-line-' + button_part_number + '-' + button_style_number, 6, relad_st);
                button_part_number = 2;
                button_style_number = 1;
                remove_button();
                button_style_number = 2;
                remove_button();
                THREE.Cache.clear();  //清除缓存
                break;
            case 8: //袖扣子/扣眼/扣线 (4)
                button_style_number = style_number;
                button_part_number = 4;
                //清除扣子相关
                remove_button();
                THREE.Cache.clear();  //清除缓存
                if (style_number > 6) {  //法式没有扣线
                    if (button_model_number < 100) {
                        button_model_number = 101;  //切回第一款扣子
                        button_hole_model_number = 101;
                    }
                    load_obj_mtl('fa-button-' + button_part_number + '-' + button_style_number, 4, relad_st);
                    load_obj_mtl('fa-button-hole-' + button_part_number + '-' + button_style_number, 5, relad_st);
                } else {
                    if (button_model_number > 100) {
                        button_model_number = 1; //切回第一款扣子
                        button_hole_model_number = 1;
                    }
                    load_obj_mtl('button-' + button_part_number + '-' + button_style_number, 4, relad_st);
                    load_obj_mtl('button-hole-' + button_part_number + '-' + button_style_number, 5, relad_st);
                    load_obj_mtl('button-line-' + button_part_number + '-' + button_style_number, 6, relad_st);
                }
                break;
            case 9: //短袖 清除所有袖扣（因为是直接清除袖口和袖衩所有就不单独执行11了）
                button_part_number = 4;
                button_style_number = style_number;
                remove_button();
                button_part_number = 5;
                button_style_number = 1;
                remove_button();
                THREE.Cache.clear();  //清除缓存
                break;
            case 11: //袖衩(5)（袖衩只有普通扣,切换为法式时仍然加载普通扣）
                button_part_number = 5;
                button_style_number = 1;
                //清除普通扣子相关
                remove_button();
                THREE.Cache.clear();  //清除缓存
                if (style_number >= 3) {  //法式没有扣线
                    load_obj_mtl('fa-button-' + button_part_number + '-' + button_style_number, 4, relad_st);
                    load_obj_mtl('fa-button-hole-' + button_part_number + '-' + button_style_number, 5, relad_st);
                } else {
                    load_obj_mtl('button-' + button_part_number + '-' + button_style_number, 4, relad_st);
                    load_obj_mtl('button-hole-' + button_part_number + '-' + button_style_number, 5, relad_st);
                    load_obj_mtl('button-line-' + button_part_number + '-' + button_style_number, 6, relad_st);
                }
                break;
            default :
                break;
        }
    }
    //截图
    window.screenGoodsimg = function () {
        window.goodsimg = '';
        //商品模型回正 加载完整？
        camera.position.set(0, 0, 10); //相机位置
        camera.lookAt(new THREE.Vector3(0, 0, 0));  //lookAt()设置相机所看的位置
        renderer.render(scene, camera);//调用WebGLRenderer的render函数刷新场景
        //获取商品图片
        var image = new Image();
        image.src = canvas.toDataURL("image/png");
        var img_data = image.src;  //图片数据
        $('.screenshot_img').html(image);
        var url = "uploadshot";
        $.ajax({
            url: url,
            type: 'post',
            data: {ids: img_data},
            success: function (d) {
                window.goodsimg = d.data.img_data;
            }
        });
        window.model_data = get_all_data(); //获取模型快照
    };

    // function getOsssign() {
    //     var url = "https://tfast.jscytech.com/api/Ossdeal/getOsssign";
    //     $.ajax({
    //         url: url,
    //         type: 'get',
    //         success: function (d) {
    //             console.log(d);
    //         }
    //     });
    // }

    //获取模型快照
    function get_all_data() {
        var all_data = [];
        all_data[0] = part_style_record; //商品当前所有部位款式记录
        all_data[1] = goods_map_number;  //商品各部位对应编号下的贴图编号(面料号)
        all_data[2] = button_model_number;  //钮扣模型编号
        all_data[3] = button_material_number;  //钮扣材质编号
        all_data[4] = button_map_number;  //钮扣贴图编号(钮扣号)
        all_data[5] = button_hole_model_number;  //扣眼模型编号
        all_data[6] = cur_shirt_long;  //长短袖
        all_data[7] = cur_show_pocket;  //有无口袋
    }
};
export{
    tdmodel,
}




