<!-- -->

<template>
  <div class="modes">
    <div class="models_model" style="margin-top: 15%">
		<div class="body" style="width: 600px;">
			<canvas id="embCanvas" width="2500" height="2500" style="position: fixed;z-index: 1;display: none; border: 1px dashed darkblue"></canvas>
			<canvas id="embCanvas1" width="2500" height="2500" style="position: fixed;z-index: 1;display: none; border: 1px dashed darkblue"></canvas>
			<canvas id="mainCanvas" class="cover" width="600" height="540"></canvas>
		</div>
    </div>
      <div class="models_content">
          <h3>性别</h3>
          <div class="con_item">
              <div class="item" v-for="(item,i) in sex" :key="i" @click="handelsex(item.id)">
                  <el-button>{{item.title}}</el-button>
              </div>
          </div>
      </div>
      <div class="models_content">
          <h3>款式</h3>
          <div class="con_item">
              <div class="item" v-for="(item,i) in sleeve" :key="i" @click="handelsleeve(item.id)">
                  <el-button>{{item.title}}</el-button>
              </div>
          </div>
      </div>
    <div class="models_right">
      <div class="models_content">
        <h3>主面料</h3>
        <div class="con_item">
          <div class="item switch_map" v-for="(item,i) in images" :key="i" :data-map_number="item.id" @click="handelItem(item.id)">
            <img :data-map_number="item.id" :src="item.images" alt />
          </div>
        </div>
      </div>

        <div class="models_content">
            <h3>印花类型</h3>
            <div class="con_item">
                <select class="yinhualx"  @change="changeyhlx()">
                    <option v-for="(options,i) in yinhualx" :key="i" :value="options.showid">{{options.text}}</option>
                </select>
            </div>
        </div>



	  <div class="models_content">
	    <h3>拼色</h3>

        <select class=""  @change="selectClass()">
            <option value="1">不拼色</option>
          <option v-for="(options,i) in selectMapData" :key="i" :value="options.id">{{options.title}}</option>
        </select>
	    <div class="con_item">
	      <div class="item" v-for="(item,id) in pinse" :key="id" @click="handelItemsub(item.id)">
	        <img :src="item.images" alt />
	      </div>
	    </div>
	  </div>


    <div class="models_content">
        <h3>领型</h3>
        <div class="con_item">
            <div class="item" v-for="(item,i) in ling" :key="i" @click="handelXiu(item.part,item.style)">
                <el-button>{{item.text}}</el-button>
            </div>
        </div>
    </div>
      <div class="models_content">
        <h3>袖型</h3>
        <div class="con_item">
          <div class="item" v-for="(item,i) in xiu" :key="i" @click="handelXiu(item.part,item.style)">
            <el-button>{{item.text}}</el-button>
          </div>
        </div>
      </div>
    <div class="models_content">
        <h3>纽扣</h3>
        <div class="con_item">
            <div class="item" v-for="(item,i) in niukou" :key="i" @click="changeniukou(item.model,item.material,item.showid)">
                <el-button>{{item.text}}</el-button>
            </div>
        </div>
    </div>
    <div class="models_content">
        <h3>绣花类型</h3>
        <div class="con_item">
            <select class="xiuhuatype"  @change="changexhlx()">
                <option v-for="(options,i) in xiuhualx" :key="i" :value="options.showid">{{options.text}}</option>
            </select>
        </div>
    </div>
        <div class="models_content">
            <h3>绣花文字</h3>
            <div class="con_item">
                <input id="emb_content" type="input" value="" />
            </div>
        </div>
        <div class="models_content">
            <h3>绣花风格</h3>
            <div class="con_item">
                <div class="item" v-for="(item,i) in xiuhuafg" :key="i" @click="changexhfg(item.type,item.font)">
                    <el-button>{{item.name}}</el-button>
                </div>
            </div>
        </div>
        <div class="models_content">
            <h3>绣花颜色+部位</h3>
            <div class="con_item">
                <div class="item" v-for="(item,i) in xiuhuabw" :key="i" @click="changexhbw(item.type)">
                    <el-button>{{item.name}}</el-button>
                </div>
            </div>
            <div class="con_item">
                <div class="item" v-for="(item,i) in xiuhuays" :key="i" @click="changexhys(item.id,item.color)">
                    <el-button>{{item.name}}</el-button>
                </div>
            </div>
        </div>
        <div class="models_content">
            <h3>绣花-生肖</h3>
            <div class="con_item">
                <div class="item" v-for="(item,i) in xiuhuasx" :key="i" @click="changeembsx(item.type,item.showid)">
                    <el-button>{{item.name}}</el-button>
                </div>
            </div>
        </div>
        <div class="models_content">
            <h3>绣花-星座</h3>
            <div class="con_item">
                <div class="item" v-for="(item,i) in xiuhuaxz" :key="i" @click="changeembsx(item.type,item.showid)">
                    <el-button>{{item.name}}</el-button>
                </div>
            </div>
        </div>
        <div class="models_content">
            <h3>印花类型</h3>
            <div class="con_item">
                <select class="yinhualx"  @change="changeyhlx()">
                    <option v-for="(options,i) in yinhualx" :key="i" :value="options.showid">{{options.text}}</option>
                </select>
            </div>
        </div>
        <div class="models_content">
            <h3>印花山水</h3>
            <div class="con_item">
                <div class="item" v-for="(item,i) in yinhuass" :key="i" @click="changeyh(item.type,item.showid)">
                    <el-button>{{item.name}}</el-button>
                </div>
            </div>
        </div>
        <div class="models_content">
            <h3>印花花鸟</h3>
            <div class="con_item">
                <div class="item" v-for="(item,i) in yinhuahn" :key="i" @click="changeyh(item.type,item.showid)">
                    <el-button>{{item.name}}</el-button>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import '../public/static/3d/js/jquery.min.js';
import '../public/static/3d/js/jquery-1.10.2.min.js';
import {tdmodel} from '../public/static/3d/js/td_model.js';
import {TweenMax} from '../public/static/3d/js/TweenMax.min.js';

	
	
export default {
  
  name: '',
  components: {},
  props: {},
  data() {
    return {
      images: [
        { id: 13, images: require('./assets/item1.png') },
        { id: 14, images: require('./assets/item2.png') },
        { id: 15, images: require('./assets/item3.png') },
      ],
      ling: [
        { style: 1,part:5, text: '方领' },
        { style: 2, part:5,text: '圆领' },
        { style: 3, part:5,text: '立领' },
      ],
      xiu: [
        { style: 1, part:8,text: '直角袖口' },
        { style: 2, part:8,text: '圆角袖口' },
        { style: 3,part:8, text: '平直袖口' },
      ],
	  pinse:[
		 { id: 13, images: require('./assets/item1.png')  },
		 { id: 14, images: require('./assets/item2.png') },
		 { id: 15, images: require('./assets/item3.png') },
	  ],
	  selectMapData:[
		  {id:2,title:'领子'},
		  {id:3,title:'袖口'},
		  {id:4,title:'领子+袖口'}
	  ],
        sleeve:[
            {id:8,title:'长袖'},
            {id:9,title:'短袖'}
        ],
        sex:[
            {id:1,title:'男'},
            {id:2,title:'女'}
        ],
        niukou:[
            {id:1,type:1,model:1,material:1,showid:42,text:'纽扣1'},
            {id:2,type:1,model:1,material:1,showid:41,text:'纽扣2'},
            {id:3,type:1,model:1,material:1,showid:40,text:'纽扣3'},
            {id:4,type:1,model:1,material:1,showid:39,text:'纽扣4'},
        ],
        xiuhualx:[//绣花类型
            {id:1,type:1,showid:999,text:'不绣花'},
            {id:2,type:2,showid:1,text:'字母'},
            {id:3,type:3,showid:2,text:'生肖'},
            {id:4,type:4,showid:3,text:'星座'},
            {id:5,type:6,showid:4,text:'客供'},
            {id:6,type:7,showid:6,text:'主题'},
        ],
        xiuhuafg:[
            {id:1,type:1,font:'FZCuQian-M175',name:'风格1'},
            {id:2,type:2,font:'FZYaoti',name:'风格2'},
            {id:3,type:3,font:'STXingkai',name:'风格3'},
        ],
        xiuhuasx:[
            {id:1,type:2,showid:6,name:'鼠'},
            {id:2,type:2,showid:8,name:'牛'},
            {id:2,type:2,showid:2,name:'虎'},
        ],
        xiuhuaxz:[
            {id:1,showid:1,type:3,name:'金牛'},
            {id:2,showid:2,type:3,name:'白羊'},
        ],
        xiuhuays:[//绣花颜色
            {id:1,rgb_color:'18,57,162',color:'#1239a2',line:'1393',name:'深蓝'},
            {id:2,rgb_color:'175,0,0',color:'#af0000',line:'6111',name:'大红'},
            {id:3,rgb_color:'255,226,85',color:'#e5b7ca',line:'Y2187',name:'粉色'},
        ],
        xiuhuabw:[//绣花部位
            {id:1,type:3,name:'左袖口'},
            {id:2,type:7,name:'领尖'},
            {id:3,type:1,name:'胸口'},
            {id:4,type:2,name:'下领'},
            {id:5,type:6,name:'腰部'},
            {id:6,type:5,name:'里襟'},
        ],
       yinhualx:[//绣花类型
            {id:1,type:1,showid:999,text:'不印花'},
            {id:2,type:2,showid:1,text:'山水'},
            {id:3,type:3,showid:2,text:'花鸟'},
        ],
        yinhuass:[
            {id:1,type:1,showid:1,name:'泛舟桃源'},
            {id:2,type:1,showid:2,name:'鹤语船影'},
            {id:2,type:1,showid:3,name:'山水画'},
        ],
        yinhuahn:[
            {id:1,type:2,showid:1,name:'墨兰'},
            {id:2,type:2,showid:3,name:'青花'},
            {id:2,type:2,showid:5,name:'竹'},
        ],
	  selectClassEnd:1,//拼色部位默认值
        map_sub_type:1,
    }
  },
  computed: {},
  watch: {},
  created() {

  },
    mounted() {
        tdmodel(); //初始加载模型
    },
    methods: {

    handelItem(id) {
        changeMapAll(id);
    },
    selectClass() {
        this.map_sub_type = event.target.value; //获取option对应的value值
        window.map_sub_type = this.map_sub_type;//全局拼色类型参数
    },
	handelItemsub(i) {
     //拼色面料选择
	  window.map_sub_num =i;//全局拼色面料i
      window.is_self_map = 2;//是否自备面料
      changeMapSub(window.map_sub_type,map_sub_num,window.is_self_map);
	},
  handelsleeve(i) {
      // window.cur_shirt_long =i;//长短袖
      changeStyle(i,1);
  },
  handelsex(i) {
      // modeltd = new tdmodel();
      window.model_sex =i;//性别 1男2女
      changeSexModel( window.model_sex);
  },
  handelXiu(parts,styles) {//款式
     // parts:3dclass--后台传（字符串或数字）
     // styles:款式id
      changeStyle(parts,styles);
  },
    changeniukou(model,material,map){//纽扣
        changeButton(model,material,map);
    },
    changexhlx(){//绣花类型
        this.xiuhuatype = event.target.value; //获取option对应的value值
        //alert(this.xiuhuatype);
        changeembtype(this.xiuhuatype);
    },
    changexhbw(type){//绣花部位
        changeembpart(type);
    },
    changexhys(num,color){//绣花颜色
        changeembcolor(num,color);
    },
    changexhfg(number,font){//绣花字体风格
        changeembstyle(number,font);
    },
    changeembsx(type,num){
        changeembsx(type,num);
    },
    changeyhlx(){//绣花类型

        this.printtype = event.target.value; //获取option对应的value值
        //alert(this.xiuhuatype);
        if(this.printtype ==999){//不印花
            changeprint(1,this.printtype);
        }
        // changeprinttype(this.printtype);
    },
    changeyh(type,number){
        changeprint(type,number);
    }
  }
  
}
</script>

<style>
.modes {
  display: flex;
}
.models_model {
  flex: 1;

}
.models_model .body{
	  background:#ccc;
}
.models_right {
  flex: 1;
}
.con_item {
  display: flex;
}
h3 {
  display: block;
}
.item {
  flex: 1;
  margin: 5px;
  max-width: 122px;
}
.item img{
	border:1px solid #005500;
}
.item img:hover{
	border:1px solid #aa0000;
	border-radius:4px;
	cursor:pointer;
}
</style>

